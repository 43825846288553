import { createMuiTheme, responsiveFontSizes } from "@material-ui/core";
import deepmerge from "deepmerge";
import { globalThemeStyles } from "./Global.styles";

const primary = "#c0a357"
const secondary = "#4829B2"

const lightTheme = {
  palette: {
    primary: { light: primary },
    secondary: { light: secondary },
    text: {
      primary: '#111',
      secondary: "rgba(20,20,20, 0.87)",
    },
  },
  spacing: 8
}

const themeOptions = deepmerge(globalThemeStyles, lightTheme)
export default responsiveFontSizes(createMuiTheme(themeOptions))

import clsx from 'clsx'
import GCLink from 'components/ux/GCLink'
import HomeIcon from 'components/icons/menuWide/HomeIcon'
import HorizontalDivider from 'components/icons/menuWide/HorizontalDivider'
import React, { useEffect } from 'react'
import UserIcon from 'components/icons/menuWide/UserIcon'
import VerticalDivider from 'components/icons/menuWide/VerticalDivider'
import VerticalsCasinoIcon from 'components/icons/menuWide/VerticalsCasinoIcon'
import VerticalsIcon from 'components/icons/menuWide/VerticalsIcon'
import VerticalsPokerIcon from 'components/icons/menuWide/VerticalsPokerIcon'
import VerticalsSportsbookIcon from 'components/icons/menuWide/VerticalsSportsbookIcon'
import BetFinderIcon from 'components/icons/menuWide/BetFinder'
import { Box, Typography, useTheme } from '@material-ui/core'
import { connect } from 'react-redux'
import { i18nContext } from 'services/i18n/i18n.context'
import { logout, userLoginState } from 'services/auth/auth.slice'
import { menuWideStyles } from 'components/page/menu/MenuWide.styles'
import { pathname } from 'app/routing/routes'
import { RootState } from 'app/store'
import { setSnackbar } from 'components/page/snackbar/snackbar.slice'
import { useHistory } from 'react-router-dom'
import { useMenuController } from 'components/page/menu/Menu.controller'
import MyBrandsIcon from 'components/icons/menuWide/MyBrands'
import TrackIcon from 'components/icons/menuWide/TrackIcon'
import SettingsIcon from 'components/icons/menuWide/Settings'
import JackpotIcon from 'components/icons/menuWide/Jackpot'

const mapStateToProps = (state: RootState) => {
  const { auth, i18n: { langLoaded, market } } = state
  return { ...auth, langLoaded, market }
}

const mapDispatchToProps = {
  setSnackbar, logout
}

let closeMenuTimeout: NodeJS.Timeout


function MenuWide(props: any) {
  // *** Init
  const [playOpen, setPlayOpenState] = React.useState(false)
  const [meOpen, setMeOpenState] = React.useState(false)
  const [path, setLastPath] = React.useState('')

  // Wrapper to auto-close menu after 8 seconds
  const setPlayOpen = (openState: boolean) => {
    setPlayOpenState(openState)
    if (openState) {
      setMeOpenState(false)
      closeMenuTimeout = setTimeout(() => { setPlayOpenState(false) }, 8000)
    } else {
      clearTimeout(closeMenuTimeout)
    }
  }

  const setMeOpen = (openState: boolean) => {
    setMeOpenState(openState)
    if (openState) {
      setPlayOpenState(false)
      closeMenuTimeout = setTimeout(() => { setMeOpenState(false) }, 8000)
    } else {
      clearTimeout(closeMenuTimeout)
    }
  }

  const i18nCtx = React.useContext(i18nContext)
  const { t } = i18nCtx

  const history = useHistory()
  const controller = useMenuController(history)

  // Styling
  const theme = useTheme()
  const classes = menuWideStyles(theme)

  const route = props.pageTarget?.route.name
  const renderRoute = props.renderTarget?.route.name

  // Close menu on Navigation
  useEffect(() => {
    if (renderRoute !== path) {
      setPlayOpen(false)
      setMeOpen(false)
    }
    setLastPath(renderRoute)
  }, [renderRoute, path])


  if (props.loginStatus === userLoginState.loggedOut) {
    return (
      <Box className={classes.rootLoggedOut}>
        <Box className={classes.signupCta}>
          <GCLink to={pathname.signup}>
            <Typography className={classes.ctaText}>{t('Menu.SignupCTA')}</Typography>
          </GCLink>
        </Box>
      </Box>
    )
  }


  // *** Render

  return (
    <>
      {playOpen &&
        <Box className={classes.verticalMenu} >

          <Box className={classes.verticalMenuItem} onClick={() => { controller.gotoLink('casino'); setPlayOpen(false) }}>
            <VerticalsCasinoIcon className={classes.verticalMenuIcon} />
            <Typography className={classes.verticalText}>{t(`Menu.Text.Casino`)}</Typography>
          </Box>

          <HorizontalDivider className={classes.verticalDivider} />

          <Box className={classes.verticalMenuItem} onClick={() => { controller.gotoLink('bookmaker'); setPlayOpen(false) }}>
            <VerticalsSportsbookIcon className={classes.verticalMenuIcon} />
            <Typography className={classes.verticalText}>{t(`Menu.Text.Bookmaker`)}</Typography>
          </Box>

          <HorizontalDivider className={classes.verticalDivider} />
          
          <Box className={classes.verticalMenuItem} onClick={() => { controller.gotoLink('poker'); setPlayOpen(false) }}>
            <VerticalsPokerIcon className={classes.verticalMenuIcon} />
            <Typography className={classes.verticalText}>{t(`Menu.Text.Poker`)}</Typography>
          </Box>

        </Box>
      }
      {meOpen &&
        <Box className={classes.verticalMenu} >

          <Box className={classes.verticalMenuItem} onClick={() => { controller.gotoLink('track'); setMeOpen(false) }}>
            <TrackIcon className={classes.verticalMenuIcon} />
            <Typography className={classes.verticalText}>{t(`Menu.Text.Track`)}</Typography>
          </Box>

          <HorizontalDivider className={classes.verticalDivider} />

          <Box className={classes.verticalMenuItem} onClick={() => { controller.gotoLink('my-brands'); setMeOpen(false) }}>
            <MyBrandsIcon className={classes.verticalMenuIcon} />
            <Typography className={classes.verticalText}>{t(`Menu.Text.MyBrands`)}</Typography>
          </Box>

          <HorizontalDivider className={classes.verticalDivider} />
          
          <Box className={classes.verticalMenuItem} onClick={() => { controller.gotoLink('user'); setMeOpen(false) }}>
            <SettingsIcon className={classes.verticalMenuIcon} />
            <Typography className={classes.verticalText}>{t(`Menu.Text.Settings`)}</Typography>
          </Box>

        </Box>
      }
      <Box className={classes.root}>

        <Box className={classes.menuItem} onClick={() => setPlayOpen(!playOpen)}>
          <VerticalsIcon className={classes.verticalsIcon} />
          <Typography className={classes.iconText}>{t(`Menu.Text.Verticals`)}</Typography>
        </Box>

        <VerticalDivider className={classes.menuDivider} />

        <Box className={classes.menuItem}>
          <GCLink to={pathname.jackpotTracker} className={classes.menuLink}>
            <JackpotIcon className={clsx(classes.jackpotIcon, { [classes.selected]: route === `Page.JackpotTracker` })} />
            <Typography className={classes.iconText}>{t(`Menu.Text.Jackpots`)}</Typography>
          </GCLink>
        </Box>

        <VerticalDivider className={classes.menuDivider} />

        <Box className={classes.menuItem}>
          <GCLink to={pathname.home} className={classes.menuLink} >
            <HomeIcon className={clsx(classes.homeIcon, { [classes.selected]: route === `Page.Home` })} />
          </GCLink>
        </Box>

        <VerticalDivider className={classes.menuDivider} />

        <Box className={classes.menuItem}>
          <GCLink to={pathname.oddsComparison} className={classes.menuLink}>
            <BetFinderIcon className={clsx(classes.BetFinderIcon, { [classes.selected]: route === `Page.BetFinder` })} />
            <Typography className={classes.iconText}>{t(`Menu.Text.BetFinder`)}</Typography>
          </GCLink>
        </Box>

        <VerticalDivider className={classes.menuDivider} />

        <Box className={classes.menuItem} onClick={() => setMeOpen(!meOpen)}>
            <UserIcon className={clsx(classes.userIcon, { [classes.selected]: route === `Page.User` })} />
            <Typography className={classes.iconText}>{t(`Menu.Text.User`)}</Typography>
        </Box>

      </Box>
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(MenuWide)
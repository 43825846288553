import deepmerge from 'deepmerge'
import { createMuiTheme, responsiveFontSizes } from '@material-ui/core'
import { globalThemeStyles } from 'services/theme/Global.styles'

// const primary = "red"
// const secondary = "#4829B2"
// const warning = "rgba(253, 200, 69, .7)"
// const info = "#1976d2"

// const paperBackground = "#242424"
// const background = "#202020"

const darkTheme = {

}

const themeOptions = deepmerge(globalThemeStyles, darkTheme)

export default responsiveFontSizes(createMuiTheme(themeOptions))

import {createSlice, PayloadAction } from '@reduxjs/toolkit'

export enum severity {
  error,
  warning,
  info,
  success
}

export interface ISnackBar {
  content: string,
  severity: string,
  autoHideDuration: number
}

const Snacks: ISnackBar[] = []

const initialState = {
    snacks: Snacks
}

export type SnackbarState = typeof initialState

export const snackbarSlice = createSlice({
  name: 'snackbar',
  initialState,
  reducers: {
    setSnackbar: (state, action: PayloadAction<ISnackBar>) => {
        state.snacks.push({...action.payload})
      },
    nextSnackBar:(state) => {
        state.snacks.pop()
    }
  }
})

export const { setSnackbar, nextSnackBar } = snackbarSlice.actions;

// export const selectUserId = (state: RootState) => state.auth.userId
// export const selectIsLoggedIn = (state: RootState) => state.auth.login === userLoginState.loggedIn

export default snackbarSlice.reducer;

import AppSnackbar from 'components/page/snackbar/Snackbar'
import MetaTags from 'components/page/metaTags/MetaTags'
import React, { useEffect, useReducer } from 'react'
import { addModal, nextModal, clearAllModals } from 'reducers/modal.slice'
import { baseRedirect, extractMarketLangFromPath } from 'services/lang/lang.lib'
import { connect, useDispatch } from 'react-redux'
import { i18nContext } from 'services/i18n/i18n.context'
import { initialRootState, rootReducer } from 'components/routing/root/root.slice'
import { matchRoutes, renderRoutes, RouteConfig } from 'react-router-config'
import { rebaseMarketLang, redirect, removeMarketLang } from 'services/routing/route.lib'
import { RootState } from 'app/store'
import { pathname, routes } from 'app/routing/routes'
import { Switch, useHistory } from 'react-router-dom'
import { Title } from 'components/page/title/Title'
import { userLoginState, userLogoutReason } from 'services/auth/auth.slice'
import { setSnackbar } from 'components/page/snackbar/snackbar.slice'
import { loginActions } from 'reducers/login.slice'
import { needsLoginRedirect } from 'services/auth/auth.lib'

// *** React-router Prop mapping
const mapDispatchToProps = { addModal, clearAllModals, nextModal, setSnackbar }

const mapStateToProps = (state: RootState) => {
  const { auth: { loginStatus, logoutReason }, modal, snackbar, i18n: { lang, market, langLoaded } } = state
  return { lang, market, langLoaded, loginStatus, logoutReason, ...modal, snackbar }
}

let prevLoginState = userLoginState.unknown

/**
 * The root component is processed at the start of every route.
 * It does the following:
 * 
 *  - calculates the render target, based on the browser URL
 *  - calculates the page target (the page shown if the render target is a modal, otherwise -- the same as the render target)
 * 
 *  - handles redirecting the page if the url is only a /
 *  - adds / removes modals to be displayed, as the browser URL changes
 *  - redirects to the login page, if a user tries to access a page requiring authorisation
 *  - redirects to the default home page after login
 *  
 *  - hosts any snackbars (popup messages)
 *  - changes the browser tab title
 * 
 * @param {*} props
 * @return {*} 
 */
function Root(props: any) {

  // Dynamic language handling
  const i18nCtx = React.useContext(i18nContext)
  const { t } = i18nCtx

  const dispatch = useDispatch()

  // State handling
  const [values, rootDispatch] = useReducer(rootReducer, initialRootState)

  // Routing
  let history = useHistory()

  // Destructure props into local variables
  const { location, loginStatus, logoutReason, modals, addModal, nextModal, clearAllModals, setSnackbar, setTargetPath } = props

  if (values.pageLocation.pathname === '') {
    const parts = extractMarketLangFromPath(location.pathname)
    // TODO -- market lang stuff here
    rootDispatch({ type: 'setPathName', payload: `/${ parts.marketId }/` })
  }

  // Calculate Render Target (inc modals)
  const allMatchedRoutes = matchRoutes(routes, location.pathname)
  let renderTarget: RouteConfig | undefined = undefined
  if (allMatchedRoutes.length > 0) {
    renderTarget = allMatchedRoutes[allMatchedRoutes.length - 1]
  }

  // Calculate Page Target (no modals)
  const allMatchedPageRoutes = matchRoutes(routes, values.pageLocation.pathname)
  let pageTarget: RouteConfig | undefined = undefined
  if (allMatchedPageRoutes.length > 0) {
    pageTarget = allMatchedPageRoutes[allMatchedPageRoutes.length - 1]
  }

  // Post render
  useEffect(() => {
    let red = false
     if (location.pathname === '/' && history.action === 'POP') red = true
    if (location.pathname.length < 4) red = true

    // Handles redirecting the page if required
    if (red) {
      baseRedirect(history)
    } else {

      // Redirect to login, if required
      if (needsLoginRedirect(renderTarget, prevLoginState, loginStatus, logoutReason, history)) {
        // Redirect to the login modal (after reloading the home page first)
        if (history.action === 'POP') {
          dispatch(loginActions.setTargetPath(removeMarketLang(location.pathname, renderTarget?.match.params)))
        }

        redirect(history, pathname.home)
        setTimeout(() => {
        redirect(history, pathname.login)
        }, 150)
        if (logoutReason === userLogoutReason.timeOut) {
          setSnackbar({
            content: t('Logout.Timeout'),
            severity: 'info',
            autoHideDuration: 3000
          })
        }
      }

      // On path change
      if (renderTarget?.match.url !== values.lastPath) {
        rootDispatch({ type: 'setLastPath', payload: renderTarget?.match.url })

        // Removes last modal displayed
        if (modals.length > 0 && renderTarget?.route?.isPage === true) {
          clearAllModals()
        }

        // Redirect to the default homepage, after login
        if (renderTarget?.route.needsNoAuth && loginStatus === userLoginState.loggedIn) {
          redirect(history, pathname.home)
        }

        if (!renderTarget?.route.isModal) {
          rootDispatch({ type: 'setPageLocation', payload: location })
        } else {
          // Add a modal to be displayed
          if (modals.length === 0 || modals[0].name !== renderTarget.route.name) {
            const { name, modalParams } = renderTarget.route
            if (!modalParams.stack) {
              clearAllModals()
            }
            addModal({ name, modalParams, location })
          }
        }
      }
    }

    prevLoginState = loginStatus

  }, [location, loginStatus, logoutReason, modals, history, values, renderTarget, addModal, clearAllModals, t, setSnackbar, setTargetPath, dispatch])


  // Escape Key handling for modals
  useEffect(() => {

    const maybeEsc = (e: any) => {
      if (modals.length === 0) return
      if (modals[0].modalParams.closeEsc !== true) return

      const key = e.keyCode || e.charCode || 0
      if (key !== 27) return

      nextModal()
      let route
      if (modals.length === 1) {
        route = rebaseMarketLang(pageTarget?.match.url, pageTarget?.match.params)
      } else {
        route = modals[1].location
      }
      history.push(route)
    }

    document.body.addEventListener('keyup', maybeEsc)
    return () => document.body.removeEventListener('keyup', maybeEsc)
  }, [modals, nextModal, pageTarget, history])


  // Rendering
  return (
    <>
      <Switch location={values.pageLocation}>
        {renderRoutes(props.route.routes, { renderTarget, pageTarget })}
      </Switch>
      <AppSnackbar />
      <Title pageTitle={t(renderTarget?.route.name || '')} siteTitle={t('Window.Title')} />
      <MetaTags pageTitle={t(renderTarget?.route.name || '')} />
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Root)
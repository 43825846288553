import { apiCheckLoggedIn } from 'services/api/access.checkLoggedIn'
import { apiGetCountry } from 'services/api/access.getCountry'
import { apiLogin } from 'services/api/access.login'
import { apiLogout } from 'services/api/access.logout'
import { apiSignup } from 'services/api/access.signup'
import { apiValidateEmailConfirmation } from 'services/api/access.validateEmailConfirmation'
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { resultWrapper } from 'services/api/base/resultWrapper'
import { onLogin, onLogout, onSignup } from './auth.hook'
import { apiGetUserId } from 'services/api/access.getUserId'
import { apiGoogleSignin } from 'services/api/access.googleSignin'
import { apiGoogleSigninOneTap } from 'services/api/access.googleSigninOneTap'

export enum userLoginState {
  loggedIn,
  loggedOut,
  unknown
}

export enum userLogoutReason {
  manual,
  timeOut,
  notSet
}

export interface ILoginParameters {
  username: string,
  password: string
}

export interface ILogoutParameters {
  reason: userLogoutReason
}

export interface ISignupParameters {
  email: string,
  username: string,
  password: string,
  terms: string,
  language: string,
  market: string
}

export interface IGoogleSigninParameters {
  token: string,
  terms: string,
  language: string,
  market: string
}

export interface IConfirmEmailParameters {
  userId: string,
  token: string
}

const initialState = {
  userId: '',
  username: '',
  loginStatus: userLoginState.loggedOut,
  logoutReason: userLogoutReason.notSet,
  lastMessage: '',
  lastError: '',
  greeting: '',
  hasPassword: true
}

export type AuthState = typeof initialState

export const isAuthenticated = async () => {
  const result = await apiCheckLoggedIn()
  return result.isSuccess
}

export const getUserId = createAsyncThunk(
  'auth/getUserId',
  async (args, thunkApi) => {
    const result = await apiGetUserId()
    if (result.isSuccess) {
      return result
    }
    return thunkApi.rejectWithValue(result.data || result.message)
  }
)


export const login = createAsyncThunk(
  'auth/login',
  async (args: ILoginParameters, thunkApi) => {
    const result = await apiLogin(args)
    if (result.isSuccess) {
      onLogin(result)
      return result
    }
    return thunkApi.rejectWithValue(result.data || result.message)
  }
)

export const logout = createAsyncThunk(
  'auth/logout',
  async (args: ILogoutParameters, thunkApi) => {
    const result = await apiLogout()
    if (result.isSuccess) {
      onLogout(result)
      return result
    }
    return thunkApi.rejectWithValue(result.data || result.message)
  }
)

export const signup = createAsyncThunk(
  'auth/signup',
  async (args: ISignupParameters, thunkApi) => {
    const result = await apiSignup(args)
    if (result.isSuccess) {
      onSignup(result)
      return result
    }
    return thunkApi.rejectWithValue(result)
  }
)

export const googleSignin = createAsyncThunk(
  'auth/googleSignin',
  async (args: IGoogleSigninParameters, thunkApi) => {

    const result = await apiGoogleSignin(args)
    if (result.isSuccess) {
      if (result.data.accountCreated) {
        onSignup(result)
      } else {
        onLogin(result)
      }
      return result
    }
    return thunkApi.rejectWithValue(result)
  }
)

export const googleSigninOneTap = createAsyncThunk(
  'auth/googleSigninOneTap',
  async (args: IGoogleSigninParameters, thunkApi) => {

    const result = await apiGoogleSigninOneTap(args)
    if (result.isSuccess) {
      if (result.data.accountCreated) {
        onSignup(result)
      } else {
        onLogin(result)
      }
      return result
    }
    return thunkApi.rejectWithValue(result)
  }
)

export const confirmEmailValidation = createAsyncThunk(
  'auth/confirmEmailValidation',
  async (args: IConfirmEmailParameters, thunkApi) => {
    const result = await apiValidateEmailConfirmation(args)
    if (result.isSuccess) return result
    return thunkApi.rejectWithValue(result)
  }
)

export const getCountry = createAsyncThunk(
  'auth/getCountry',
  async (args, thunkApi) => {
    const result = await apiGetCountry()
    if (result.isSuccess) return result
    return thunkApi.rejectWithValue(result)
  }
)



export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setLoggedIn: (state) => {
      state.loginStatus = userLoginState.loggedIn
      state.logoutReason = userLogoutReason.notSet
    },
    setGreeting: (state, action: PayloadAction<string>) => {
      state.greeting = action.payload
    },
    setHasPassword: (state, action: PayloadAction<boolean>) => {
      state.hasPassword = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getUserId.pending, (state, action) => {
        state.lastMessage = ''
        state.lastError = ''
      })
      .addCase(getUserId.fulfilled, (state, action) => {
        state.userId = action.payload.data as string
        state.lastMessage = 'Api.Success'
      })
      .addCase(getUserId.rejected, (state, action) => {
        state.userId = ''
        state.lastMessage = action.payload as string
      })

      .addCase(login.pending, (state, action) => {
        state.lastMessage = ''
        state.lastError = ''
      })
      .addCase(login.fulfilled, (state, action) => {
        state.userId = ''
        state.username = action.meta.arg.username
        state.loginStatus = userLoginState.loggedIn
        state.logoutReason = userLogoutReason.notSet
        state.lastMessage = 'Login.Success'
      })
      .addCase(login.rejected, (state, action) => {
        state.username = ''
        state.loginStatus = userLoginState.loggedOut
        state.lastMessage = action.payload as string
        state.lastError = action.error.message as string
      })


      .addCase(googleSignin.pending, (state, action) => {
        state.lastMessage = ''
        state.lastError = ''
      })
      .addCase(googleSignin.fulfilled, (state, action) => {
        state.userId = ''
        state.loginStatus = userLoginState.loggedIn
        state.logoutReason = userLogoutReason.notSet
        state.lastMessage = 'Login.Success'
      })
      .addCase(googleSignin.rejected, (state, action) => {
        state.username = ''
        state.loginStatus = userLoginState.loggedOut
        state.lastMessage = action.payload as string
        state.lastError = action.error.message as string
      })


      .addCase(googleSigninOneTap.pending, (state, action) => {
        state.lastMessage = ''
        state.lastError = ''
      })
      .addCase(googleSigninOneTap.fulfilled, (state, action) => {
        state.userId = ''
        state.loginStatus = userLoginState.loggedIn
        state.logoutReason = userLogoutReason.notSet
        state.lastMessage = 'Login.Success'
      })
      .addCase(googleSigninOneTap.rejected, (state, action) => {
        state.username = ''
        state.loginStatus = userLoginState.loggedOut
        state.lastMessage = action.payload as string
        state.lastError = action.error.message as string
      })



      .addCase(logout.pending, (state, action) => {
        state.lastMessage = ''
        state.lastError = ''
      })
      .addCase(logout.fulfilled, (state, action) => {
        state.username = ''
        state.userId = ''
        state.loginStatus = userLoginState.loggedOut
        state.logoutReason = action.meta.arg.reason
        state.lastMessage = 'Logout.Success'
      })
      .addCase(logout.rejected, (state, action) => {
        state.username = ''
        state.userId = ''
        state.loginStatus = userLoginState.loggedOut
        state.lastMessage = action.payload as string
        state.lastError = action.error.message as string
      })


      .addCase(signup.pending, (state, action) => {
        state.lastMessage = ''
        state.lastError = ''
      })
      .addCase(signup.fulfilled, (state, action) => {
        state.lastMessage = 'Signup.Success'
      })
      .addCase(signup.rejected, (state, action) => {
        state.lastMessage = (action.payload as resultWrapper).message as string
        state.lastError = action.error.message as string
      })


      .addCase(confirmEmailValidation.pending, (state, action) => {
        state.lastMessage = ''
        state.lastError = ''
      })
      .addCase(confirmEmailValidation.fulfilled, (state, action) => {
        state.lastMessage = 'ConfirmEmail.Title.Success'
      })
      .addCase(confirmEmailValidation.rejected, (state, action) => {
        state.lastMessage = action.payload as string
        state.lastError = action.error.message as string
      })


  }
})

export const { setLoggedIn, setHasPassword } = authSlice.actions;

// export const selectUserId = (state: RootState) => state.auth.userId
// export const selectIsLoggedIn = (state: RootState) => state.auth.login === userLoginState.loggedIn

export default authSlice.reducer;

import { footerMinHeight } from 'components/page/pageFooter/PageFooter'
import { layers } from 'components/page/page/def/layers.def'
import { makeStyles, Theme } from '@material-ui/core'
import { menuBottomHeight } from 'components/page/menu/MenuWide.styles'
import { menuLeftWidth } from 'components/page/menu/MenuTall.styles'
import { menuTopHeight } from 'components/page/menu/MenuWideTop.styles'

const sharedStyles = (theme: Theme) => ({
    root: {
        color: theme.palette.primary.main,
        height: `100vh`,
    },
    fullscreenModal: {

    },
    fullscreenModalWithBottomMenu: {
    //    height: `calc(100vh - ${menuBottomHeight}px)`,
    }
})

export const pageTallStyles = makeStyles((theme) => ({
    ...sharedStyles(theme),
    menuLeft: {
        position: 'fixed',
        top: 0,
        left: 0,
        height: '100vh',
        width: menuLeftWidth,
        backgroundColor: '#314C6A',
        zIndex: layers.menu
    },
    contentAndFooterLeft: {
        height: '100%',
        width: `100%`,
        paddingLeft: `${ menuLeftWidth }px`,
        overflow: 'vertical',
        display: 'flex',
        zIndex: layers.root
    },
    contentLeft: {
        width: '100%',
        minHeight: `calc(100% - ${ footerMinHeight }px)`,
        height: 'auto',
        backgroundColor: '#fff',
        zIndex: layers.root
    },
    footerLeft: {
        width: '100%',
        height: `${ footerMinHeight }px`,
        backgroundColor: '#fff',
    },
    mask: {
        position: 'fixed',
        top: 0,
        left: 0,
        height: '100vh',
        width: '100vw',
        backgroundColor: 'rgba(20,31,51,0.2)',
        zIndex: layers.modalMask
    },
    modalTall: {
        position: 'fixed',
        top: 0,
        left: menuLeftWidth,
        height: '100vh',
        width: 375,
        backgroundColor: '#eee',
        zIndex: layers.modal,
    },

    noOverflow: {
        overflow: 'hidden'
    },
    cookieUse: {
        position: 'fixed',
        bottom: 0,
        width: '100%',
        zIndex: layers.cookieUse,
        pointerEvents: 'none'
    },
    skipPageFrame: {
        height: '100%',
        width: `100%`,
        overflow: 'vertical',
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        backgroundColor: '#ffffff',
        zIndex: layers.root
    },
}))

export const pageWideStyles = makeStyles((theme) => ({
    ...sharedStyles(theme),
    contentAndFooter: {
     //   height: '100%',
        width: `100%`,
        overflowY: 'auto',
        overflowX: 'hidden',
    },
    content: {
        width: '100%',
        minHeight: `calc(100% - ${ footerMinHeight + menuBottomHeight }px)`,

    },
    contentNoBottomMenu: {
        width: '100%',
        minHeight: `calc(100% - ${ footerMinHeight }px)`,
        height: 'auto',
        backgroundColor: theme.palette.grey[100]
    },
    menu: {
        width: '100%',
       // height: `${ menuBottomHeight }px`,
        backgroundColor: theme.palette.grey.A400
    },
    menuTop: {
        width: '100%',
        height: `${ menuTopHeight }px`,
        backgroundColor: theme.palette.grey.A400
    },
    footer: {
        width: '100%',
        height: `${ footerMinHeight }px`,
        paddingBottom: `${ menuBottomHeight + footerMinHeight }px`,
        backgroundColor: '#fff',
    },
    footerNoBottomMenu: {
        width: '100%',
        height: `${ footerMinHeight }px`,
        paddingBottom: `${ footerMinHeight }px`,
        backgroundColor: theme.palette.grey[900]
    },
    mask: {
        position: 'fixed',
        top: 0,
        left: 0,
        height: `calc(100% - ${ menuBottomHeight }px)`,
        width: '100%',
        backgroundColor: 'rgba(20,31,51,0.5)',
        zIndex: layers.modalMask
    },
    modalWide: {
        position: 'fixed',
        top: 0,
        left: 0,
        height: `calc(100% - ${ menuBottomHeight }px)`,
        width: '100%',
        backgroundColor: '#eee',
        zIndex: layers.modal,
    },
    maskLoggedOut: {
        position: 'fixed',
        top: 0,
        left: 0,
        height: `100%`,
        width: '100%',
        backgroundColor: 'rgba(20,31,51,0.5)',
        zIndex: layers.modalMask
    },
    modalWideLoggedOut: {
        position: 'fixed',
        top: 0,
        left: 0,
        height: `100%`,
        width: '100%',
        backgroundColor: '#eee',
        zIndex: layers.modal,
    },
    noOverflow: {
        overflow: 'hidden'
    },
    cookieUse: {
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: '100%',
        zIndex: layers.cookieUse,
    }
}))
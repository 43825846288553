// *** Reducer setup
export const initialRootState = {
  lastPath: '',
  pageLocation: {
    key: 'zzzzzzz', // Default to the home page
    pathname: '', // Steve to do -- work out to how set default language early
    search: '',
    hash: '',
    state: {}
  } as any
};

type rootStateType = typeof initialRootState
type rootActionType = { type: string, payload: any }

export function rootReducer(state: rootStateType, action: rootActionType) {
  switch (action.type) {
    case 'setLastPath':
      return { ...state, lastPath: action.payload }
    case 'setPageLocation':
      return { ...state, pageLocation: action.payload }
    case 'setPathName':
        return { ...state, pageLocation: {...state.pageLocation, pathname: action.payload }}
    default:
      throw new Error()
  }
}

import { Box, makeStyles, Typography, useTheme } from '@material-ui/core'
import { i18nContext } from 'services/i18n/i18n.context'
import React from 'react'

export const footerMinHeight = 50

export function PageFooter(props: any) {

  const theme = useTheme()

  const useStyles = makeStyles((theme) => ({
    root: {
      color: theme.palette.text.primary,
      minHeight: `${footerMinHeight}px`,
      width: '100%',
      display: 'flex'
    },
    text: {
      color: '#cccccc'
    }
  }))

const classes = useStyles(theme)

const i18nCtx = React.useContext(i18nContext)
const { t } = i18nCtx

  return (
    <Box className={classes.root}>
      <Box m="auto">
        <Typography className={classes.text} variant='subtitle2'>&#169; {t('Footer.Copyright')}</Typography>
      </Box>
    </Box>
  )
}
import { apiGetLatestTerms } from 'services/api/terms.getLatestTerms'
import { apiGetLatestTermsVersion } from 'services/api/terms.getLatestTermsVersion'
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'app/store'

interface ITerms {
  lang: string,
  version: string,
  text: string
}

interface ITermsRequest {
  lang: string
}

const initialTerms: Record<string, ITerms> = {}

const initialState = {
  terms: initialTerms,
  lastMessage: '',
  lastError: ''
}

export type TermsState = typeof initialState

export const getLatestTermsVersion = async () => {
  const result = await apiGetLatestTermsVersion()
  if (!result.isSuccess) return null
  return result.data
}

export const loadTerms = createAsyncThunk(
  'terms/loadTerms',
  async (args: ITermsRequest, thunkApi) => {
    try {

      var latestVersion = await apiGetLatestTermsVersion()
      if (!latestVersion.isSuccess) return thunkApi.rejectWithValue(latestVersion.data ?? latestVersion.message)

      const { dispatch, getState } = thunkApi
      const state = getState() as RootState

      if (state.terms.terms[args.lang].version === latestVersion.data)
        return true;

      var importLang = await apiGetLatestTerms(args.lang)
      if (!importLang.isSuccess) return thunkApi.rejectWithValue(importLang.data ?? importLang.message)

      const newTerms = { lang: args.lang, text: importLang.data.text, version: importLang.data.version }
      dispatch(setTerms(newTerms))

      return true
    } catch (err) {
      return thunkApi.rejectWithValue(err.message)
    }
  }
)

export const termsSlice = createSlice({
  name: 'terms',
  initialState,
  reducers: {
    setTerms: (state, action: PayloadAction<ITerms>) => {
      state.terms[action.payload.lang] = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(loadTerms.pending, (state, action) => {
        state.lastMessage = ''
        state.lastError = ''
      })
      .addCase(loadTerms.fulfilled, (state, action) => {
        state.lastMessage = "Api.Success"
      })
      .addCase(loadTerms.rejected, (state, action) => {
        state.lastMessage = action.payload as string
        state.lastError = action.error.message as string
      })
  }
})

export const { setTerms } = termsSlice.actions;

export default termsSlice.reducer;

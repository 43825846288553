import { Box, makeStyles, useTheme } from '@material-ui/core'
import { i18nContext } from 'services/i18n/i18n.context'
import React from 'react'
import GCLink from 'components/ux/GCLink'
import { pathname } from 'app/routing/routes'

export function PageHeader(props: any) {

  const theme = useTheme()

  const useStyles = makeStyles((theme) => ({
    header: {
      width: '100%',
  },
  pageLogo: {
      marginTop: 48,
      marginLeft: 145,
      height: 110,
  },
  loginLink: {
      color: 'black',
      display: 'inline-block',
      height: 54,
      lineHeight: '54px',
      float: 'right',
      fontSize: 16,
      margin: '20px 48px 0 0',
  },
  signupLink: {
      color: 'black',
      display: 'inline-block',
      height: 54,
      lineHeight: '54px',
      float: 'right',
      fontSize: 16,
      margin: '20px 48px 0 0',
  }
  }))

  const classes = useStyles(theme)

  const i18nCtx = React.useContext(i18nContext)
  const { t } = i18nCtx

  return (
    <Box className={classes.header}>
      <GCLink to={pathname.home}>
        <img className={classes.pageLogo} src="/landing-page/gamblersconcierge-logo-horizontal.svg" alt=""/>
      </GCLink>
      <GCLink to={pathname.signup} className={classes.signupLink}>{t('Page.Signup')}</GCLink>
      <GCLink to={pathname.login} className={classes.loginLink}>{t('Page.Login')}</GCLink>
    </Box>
  )
}
export enum endpoint{
  addUserBrand = 'UserBrands/AddUserBrandEvent',
  checkForgottenPasswordParams = 'Access/CheckForgottenPasswordToken',
  checkLoggedIn = 'Access/CheckLoggedIn',
  deleteTrackerActivity = 'Tracker/DeleteActivity',
  getActivityById = 'Tracker/GetActivityByID',
  getAllBrandsForLang = 'UserBrands/GetAllBrandsForLang',
  getAllBrandsForMarketLang = 'UserBrands/GetAllBrandsForMarketLang',
  getAllCurrencies = 'Currency/GetAllCurrencies',
  getBanners = 'Banner/GetBanners',
  getCountry = 'Access/GetCountry',
  getEntitiesForJackpot = 'Jackpot/FindEntitiesForJackpot',
  getJackpotInfo = 'Jackpot/GetJackpotInfo',
  getJackpots = 'Jackpot/GetJackpots',
  getLang = 'Translation/GetLang',
  getLatestTerms = 'Terms',
  getLatestTermsVersion = 'Terms/GetLatestTermsVersion',
  getSuggestedBrands = 'UserBrands/GetSuggestedBrands',
  getTrackerActivity = 'Tracker/GetActivityV2',
  getTrackerActivityStats = 'Tracker/GetActivityStatsV2',
  getUserBrands = 'UserBrands/GetUserBrands',
  getUserId = 'Access/GetUserId',
  getUserJackpotGames = 'Jackpot/GetUserJackpotGames',
  getUnsubscribePreferences = 'UserPreference/GetUnsubscribePreferences',
  getUserPreferences = 'UserPreference/GetPreferences',
  getOddsFavorites = 'Odds/GetFavorites',
  googleSignin = 'Access/CheckGoogleToken',
  googleSigninOneTap = 'Access/CheckGoogleTokenOneTap',
  launchBrand = 'UserBrands/LaunchBrandEvent',
  launchBrandRegistrationOffer = 'UserBrands/LaunchBrandRegistrationOfferEvent',
  login = 'Access/Login',
  logout = 'Access/Logout',
  logJackpotSlug = 'Jackpot/LogSlug',
  modifyTrackerActivity = 'Tracker/ModifyActivity',
  refreshToken = 'Access/RefreshToken',
  readBrandReview = 'UserBrands/ReadBrandReviewEvent',
  recordUserOddsEvent ='Odds/RecordUserOddsEvent',
  removeOddsEvent = 'Odds/RemoveFavorite',
  removeUserBrand = 'UserBrands/RemoveUserBrandEvent',
  resendEmailVerification = 'Access/ResendEmailVerification',
  saveBannerAction = 'Banner/BannerAction',
  saveTrackerActivity = 'Tracker/SaveActivity',
  saveOddsEvent = 'Odds/AddFavorite',
  sendForgottenPassword = 'Access/ForgottenPassword',
  signup = 'Access/Signup',
  updateForgottenPassword = 'Access/ValidateResetForgottenPassword',
  updateMarketLang = 'MarketLang/UpdateMarketLang',
  updateUserPreferences = 'UserPreference/UpdatePreferences',
  updateJackpotNotification = 'Jackpot/UpdateUserJackpotGames',
  updateUnsubscribePreferences = 'UserPreference/UpdateUnsubscribePreferences',
  userResetPassword = 'Access/UserResetPassword',
  validateEmailConfirmation = 'Access/ValidateEmailConfirmation',
} 
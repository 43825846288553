import { store } from 'app/store'
import { marketLang } from 'reducers/myBrands.slice'
import { getCountry } from 'services/auth/auth.slice'
import { loadLang, setMarket } from 'services/i18n/i18n.slice'
import { defaultStorage } from 'services/storage/storage'

export const detectBrowserLanguage = (): string => {
  if (typeof navigator === `undefined`) {
    return "en"
  }

  const lang = navigator && navigator.language && navigator.language.split("-")[0]
  if (!lang) return "en"

  switch (lang) {
    case "ja":
    case "vi":
    case "th":
      return lang
    default:
      return "en"
  }
}

export const defaultLangForMarket = (market: string): string => {
  switch (market) {
    case "ja":
    case "vi":
    case "th":
      return market
    default:
      return "en"
  }
}

export const validateMarket = (market: string): boolean => {
  switch (market) {
    case "ja":
    case "vi":
    case "th":
    case "en":
      return true
    default:
      return false
  }
}

export const validateLang = (lang: string): boolean => {
  switch (lang) {
    case "ja":
    case "vi":
    case "th":
    case "en":
      return true
    default:
      return false
  }
}

export const baseRedirect = (history: any, market?: string, lang?: string) => {
  const defaultMarket = market || detectBrowserLanguage()
  const defaultLang = lang || defaultLangForMarket(defaultMarket)

  const redirectRoute = defaultLang === defaultMarket ? `/${ defaultMarket }/` : `/${ defaultMarket }/${ defaultLang }/`

  const { dispatch } = store

  dispatch(loadLang({ language: defaultLang }))
  dispatch(setMarket(defaultMarket))

  history.push(redirectRoute)
}

export const extractMarketLangFromPath = (pathname: string): marketLang => {

  let marketId = ''
  let language = ''
  const parts = pathname.split('/')

  if (parts.length > 1) {
    if (parts[1].length === 2) {
      if (validateMarket(parts[1])) marketId = parts[1]
    }

    if (parts.length > 2) {
      if (parts[2].length === 2) {
        if (validateLang(parts[2])) language = parts[2]
      }
    }
  }

  return { marketId, language } as marketLang
}


export const getCorrectedUrl = async (pathname: string, store: any): Promise<string> => {

  // See if market & lang have been saved within state
  let storedMarket = defaultStorage.state.market
  let storedLang = defaultStorage.state.lang 

  // Patch for incorrectly stored JP in market
  if (storedMarket === 'jp') {
    defaultStorage.state = {...defaultStorage.state, market: 'ja'}
    storedMarket = 'ja'
  }

  if (typeof storedMarket === 'undefined') {
  
    let marketViaIp  = 'XX' // where XX is unknown
    // If not -- get market from IP address from server
    const result = await store.dispatch(getCountry())
    if (result.payload.isSuccess) marketViaIp = result.payload.data

    // Patch for Japanese language code
    if (marketViaIp === 'JP') marketViaIp = 'JA'
    marketViaIp = marketViaIp.toLowerCase()

    // get browser lang
    const browserLang = detectBrowserLanguage()

    // If market address from server is valid -- use that
    // else -- use browser lang
    if (marketViaIp === 'xx' || !validateMarket(marketViaIp)) {
      marketViaIp = browserLang
    }

    // Store Market and Lang
    defaultStorage.state = {...defaultStorage.state, market: marketViaIp, lang: browserLang}
    storedMarket = marketViaIp
    storedLang = browserLang
  }

  // Check URL to see if valid market has been supplied.
  // if not -- add market/lang to front and return

  // if a two char lang exists -- ensure it's valid, else remove and return

  const redirectRoute = storedLang === storedMarket ? `/${ storedMarket }` : `/${ storedMarket }/${ storedLang }`

  const parts = pathname.split('/')

  if (parts.length === 1) {
    return `${redirectRoute}/`
  }

  let valid = false
  if (parts.length > 1) {
    if (parts[1].length === 2) {
      if (validateMarket(parts[1])) valid = true
    }
  }

  if (valid) return pathname

  if (pathname.startsWith('/')) {
    return `${redirectRoute}${pathname}`
  }

  return `${redirectRoute}/${pathname}`
}
import MenuTall from 'components/page/menu/MenuTall.component'
import MenuWide from 'components/page/menu/MenuWide.component'
import MenuWideTop from 'components/page/menu/MenuWideTop.component'
import Modal from 'components/page/modal/Modal.component'
import React, { Suspense, useEffect } from 'react'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { Box, Grid } from '@material-ui/core'
import { connect } from 'react-redux'
import { nextModal } from 'reducers/modal.slice'
import { pageController } from 'components/page/page/Page.controller'
import { PageFooter } from 'components/page/pageFooter/PageFooter'
import { pageTallStyles, pageWideStyles } from 'components/page/page/Page.styles'
import { RootState } from 'app/store'
import { useHistory } from 'react-router-dom'
import { userLoginState } from 'services/auth/auth.slice'
import { useTheme } from '@material-ui/core/styles'
import clsx from 'clsx'
import CookieUse from '../cookieUse/CookieUse.component'
import { StorageContext } from 'services/storage/storage'
import Loader from 'components/loader/Loader'
import { PageHeader } from '../pageHeader/PageHeader'
import { layers } from './def/layers.def'
import FullScreenModalContainer from '../fullscreenModal/FullscreenModalContainer.component'

const mapStateToProps = (state: RootState) => {
  const { auth, modal, storage } = state
  return { ...auth, ...modal, ...storage }
}

const mapDispatchToProps = {
  nextModal
}

function Page(props: any) {
  const storage = React.useContext(StorageContext)
  const history = useHistory()
  const controller = new pageController(
    history,
    props.modals,
    props.nextModal,
    props.pageTarget
  )

  // Apply themeing
  const theme = useTheme()
  const menuLeft = useMediaQuery('(min-width:768px)');

  // Always scroll to top when navigating to a new page on mobile
  useEffect(() => {
    const unlisten = history.listen(() => {
        window.scrollTo(0,0)
    })
    return () => {
      unlisten()
    }
  }, [])



  if (menuLeft) {
    const classes = pageTallStyles(theme)

    let skipFrame = props.pageTarget.route.skipPageFrame && props.pageTarget.route.skipPageFrame(props)
    let skipFrameHeader = skipFrame && props.pageTarget.route.skipPageFrameHeader && props.pageTarget.route.skipPageFrameHeader(props)
    let customModal = props.modals.length > 0 && props.renderTarget.route.customModal
    const noOverflow = (props.modals.length > 0) || props.fullscreenModal === true

    return (
      <Box className={clsx(classes.root, { [classes.noOverflow]: noOverflow})}>
        {skipFrame &&
          <Box className={classes.skipPageFrame}>
            {!skipFrameHeader &&
              <PageHeader />
            }
            {props.children}
          </Box>
        }
        {!skipFrame &&
          <>
            <Box className={classes.menuLeft}>
              <MenuTall pageTarget={props.pageTarget} renderTarget={props.renderTarget} loginStatus={props.loginStatus} />
            </Box>
            <Grid container className={classes.contentAndFooterLeft}>
              <Grid item className={classes.contentLeft}>
                {props.children}
              </Grid>
              <Grid item className={classes.footerLeft}>
                <PageFooter />
              </Grid>
            </Grid>
          </>
        }
        {props.modals.length > 0 && customModal &&
          <>
            <Box className={classes.mask} onClick={controller.closeModal} />
            <Suspense fallback={<Loader />}>
              <props.renderTarget.route.customModal {...props} />
            </Suspense>
          </>
        }
        {props.modals.length > 0 && !customModal &&
          <>
            <Box className={classes.mask} onClick={controller.closeModal} />
            <Box className={classes.modalTall}>
              <Suspense fallback={<Loader />}>
                <Modal route={props.route} pageTarget={props.pageTarget} renderTarget={props.renderTarget} />
              </Suspense>
            </Box>
          </>
        }
        {storage.state?.acceptCookies !== 'true' &&
          <Box className={classes.cookieUse}>
            <CookieUse />
          </Box>
        }
        <Box className={classes.fullscreenModal}>
          <FullScreenModalContainer />
        </Box>
      </Box>
    )
  }

  const classes = pageWideStyles(theme)
  const noBottomMenu = props.loginStatus === userLoginState.loggedOut // && props.pageTarget?.route.name !== 'Page.Signup'
  const contentClass = noBottomMenu ? classes.contentNoBottomMenu : classes.content
  const footerClass = noBottomMenu ? classes.footerNoBottomMenu : classes.footer
  const skipFrame = props.pageTarget.route.skipPageFrame && props.pageTarget.route.skipPageFrame(props)
  const skipFrameHeader = skipFrame && props.pageTarget.route.skipPageFrameHeader && props.pageTarget.route.skipPageFrameHeader(props)
  const noOverflow = (props.modals.length > 0) || props.fullscreenModal === true

  return (
    <Box className={clsx(classes.root, { [classes.noOverflow]: noOverflow })}>
      {props.loginStatus === userLoginState.loggedOut && !skipFrameHeader &&
        <Box className={classes.menuTop} style={{ position: 'fixed', top: 0, left: 0 }}>
          <MenuWideTop pageTarget={props.pageTarget} renderTarget={props.renderTarget} loginStatus={props.loginStatus} />
        </Box>
      }
      <div className={classes.contentAndFooter}>
        <div className={contentClass}>
          {props.children}
        </div>
        <div className={footerClass}><PageFooter /></div>
        {!noBottomMenu &&
          <div className={classes.menu} style={{ position: 'fixed', bottom: 0, left: 0, zIndex: layers.menu }}>
            <MenuWide pageTarget={props.pageTarget} renderTarget={props.renderTarget} loginStatus={props.loginStatus} />
          </div>
        }
      </div>
      {props.modals.length > 0 &&
        <>
          <Box className={clsx({[classes.maskLoggedOut]: noBottomMenu, [classes.mask]: !noBottomMenu})} onClick={controller.closeModal} />
          <Box className={clsx({[classes.modalWideLoggedOut]: noBottomMenu, [classes.modalWide]: !noBottomMenu})}>
            <Suspense fallback={<Loader />}>
              <Modal route={props.route} pageTarget={props.pageTarget} renderTarget={props.renderTarget} />
            </Suspense>
          </Box>
        </>
      }
      {storage.state?.acceptCookies !== 'true' &&
        <Box className={classes.cookieUse}>
          <CookieUse />
        </Box>
      }
      <Box className={clsx({[classes.fullscreenModal]: noBottomMenu, [classes.fullscreenModalWithBottomMenu]: !noBottomMenu})}>
        <FullScreenModalContainer />
      </Box>
    </Box>

  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Page)
import GCLink from 'components/ux/GCLink'
import GCTextIcon from 'components/icons/GCTextIcon'
import React from 'react'
import { Box, Typography, useTheme } from '@material-ui/core'
import { i18nContext } from 'services/i18n/i18n.context'
import { menuWideTopStyles } from 'components/page/menu/MenuWideTop.styles'
import { pathname } from 'app/routing/routes'


function MenuWideTop(props: any) {
  // *** Init
  const i18nCtx = React.useContext(i18nContext)
  const { t } = i18nCtx

  // *** Styling
  const theme = useTheme()
  const classes = menuWideTopStyles(theme)

  // *** Render
  return (
    <Box className={classes.root}>
      <Box className={classes.logoContainer}>
        <GCLink to={pathname.home}><GCTextIcon className={classes.logo} /></GCLink>
      </Box>
      {props.pageTarget?.route.name !== 'Page.Login' &&
        <Box className={classes.login}>
          <GCLink to={pathname.login}><Typography className={classes.loginText}>{t('Page.Login')}</Typography></GCLink>
        </Box>
      }
    </Box>
  )
}

export default MenuWideTop
import { pathname } from 'app/routing/routes'
import { redirect } from 'services/routing/route.lib'
import React, { useMemo } from 'react'

class menuController {

  public constructor(
    private history: any
  ) { }

  public gotoSignup = async () => {
    redirect(this.history, pathname.signup)
  }

  public gotoLogin = async () => {
    redirect(this.history, pathname.login)
  }

  public gotoLink = (name: string) => {
    redirect(this.history, name.toLowerCase())
  }
}


export const useMenuController = (history: any) => {
  
  return useMemo(() => new menuController(
    history
  ), [history])

}
import { Box, Button, Typography, useTheme } from "@material-ui/core"
import { pathname } from "app/routing/routes"
import GCLink from "components/ux/GCLink"
import React from "react"
import { i18nContext } from "services/i18n/i18n.context"
import { splitTextForLink } from "services/links/links.lib"
import { StorageContext } from "services/storage/storage"
import { cookieUseController } from "./CookieUse.controller"
import { cookieUseStyles } from "./CookieUse.styles"

function CookieUse(props: any) {

  const i18nCtx = React.useContext(i18nContext)
  const { t } = i18nCtx

  const storage = React.useContext(StorageContext)

  const controller = new cookieUseController(storage)

  const linkSplit = splitTextForLink(t('CookieUsage.Text.Usage'), 'cookieLink')

  // Styling
  const theme = useTheme()
  const classes = cookieUseStyles(theme)

  return (
    <Box className={classes.root}>
      <Box className={classes.text}>
        <Typography className={classes.textHolder}>{linkSplit.pre} <GCLink className={classes.cookieLink} to={pathname.cookie}>{linkSplit.link}</GCLink>{linkSplit.post}.</Typography>
      </Box>
      <Box className={classes.buttonHolder}>
        <Button className={classes.ok} onClick={controller.confirm}>{t('CookieUsage.Text.Ok')}</Button>
      </Box>
    </Box>
  )

}

export default CookieUse
import CasinoIcon from 'components/icons/CasinoIcon'
import HomeIcon from 'components/icons/HomeIcon'
import NewsIcon from 'components/icons/NewsIcon'
import PokerIcon from 'components/icons/PokerIcon'
import React from 'react'
import SportsIcon from 'components/icons/SportsIcon'
import TrackIcon from 'components/icons/TrackIcon'
import UserIcon from 'components/icons/UserIcon'
import MyBrands from 'components/icons/MyBrands'
import BetFinder from 'components/icons/BetFinder'
import Jackpot from 'components/icons/menuWide/Jackpot'

export default function MenuIcon(props: any) {
  switch (props.type) {
    case 'Track':
      return <TrackIcon {...props} />
    case 'News':
      return <NewsIcon {...props} />
    case 'Casino':
      return <CasinoIcon {...props} />
    case 'Bookmaker':
    case 'Sports':
      return <SportsIcon {...props} />
    case 'Poker':
      return <PokerIcon {...props} />
    case 'User':
      return <UserIcon {...props} />
    case 'MyBrands':
      return <MyBrands {...props} />
    case 'BetFinder':
      return <BetFinder {...props} />
    case 'JackpotTracker':
      return <Jackpot {...props} />
    default:
      return <HomeIcon {...props} />
  }
}
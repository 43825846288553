import ReactDOM from 'react-dom'

const titleEl = document.getElementsByTagName('title')[0]

export function Title(props: any) {

    let fullTitle = props.pageTitle ? `${props.pageTitle} - ${props.siteTitle}` : props.siteTitle

    return ReactDOM.createPortal(
        fullTitle || "",
        titleEl
    );

}

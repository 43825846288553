import { Box } from '@material-ui/core';
import React from 'react';

function Wip(props: any) {

  return (
    <Box
        display="flex" 
        color='#111111'
        height='100%'
        alignItems="center"
        justifyContent="center"
      >
      A work in progress
    </Box>
  )
}

export default Wip
import LangRoute from 'components/routing/langRoute/LangRoute'
import MarketRoute from 'components/routing/marketRoute/MarketRoute'
import Root from 'components/routing/root/Root.component'
import Wip from 'components/wip/Wip'
import {
  activityHistory, bookmaker, casino, changePassword, communicationPrefs, confirmEmail, cookie,
  forgottenPassword, home, loggedOutModal, login, marketLanguage, myBrands,
  news, notFound, poker, signup, terms, tracker, user, privacyPolicy, embed, redirect, unsubscribe,
  oddsComparison, oddsComparisonExternal, jackpotTracker, jackpotTrackerExternal
} from './dynamicComponents'
import { RouteConfig } from 'react-router-config'
import { userLoginState } from 'services/auth/auth.slice'

export interface GCRoute extends RouteConfig {
  name?: string,
  needsAuth?: boolean,
  needsNoAuth?: boolean,
  isPage?: boolean,
  isModal?: boolean,
  modalParams?: modalParams,
  loadPath?: string,
  skipPageFrame?: Function,
  skipPageFrameHeader?: Function,
  customModal?: Function,
}

export type modalParams = {
  name: string,
  closeOutside: boolean,
  closeEsc: boolean,
  backButton: boolean,
  closeButton: boolean,
  stack: boolean, // If false (default), then replaces current modal. If true, then stacks on top.
  noBackdrop?: boolean,
  customHolderStyles?: any,
}

const defaultModalParams: Partial<modalParams> = {
  closeOutside: true,
  closeEsc: true,
  backButton: false,
  closeButton: true,
  stack: false
}

// REMEMBER TO ADD new paths to public/_worker.js, for 404 processing
export enum pathname {
  activityHistory = 'activity-history',
  bookmaker = 'bookmaker',
  casino = 'casino',
  changeEmail = 'account/change-email',
  changePassword = 'account/change-password',
  communicationPrefs = 'account/communication-preferences',
  confirmEmail = 'confirmEmail',
  cookie = 'cookie',
  jackpotTracker = 'jackpot-tracker',
  jackpotTrackerExternal = 'jackpot-tracker-external',
  jgEmbed = 'jgembed',
  privacyPolicy = 'privacy-policy',
  forgottenPassword = 'forgotten-password',
  home = '',
  login = 'login',
  marketLanguage = 'account/market-language',
  myBrands = 'my-brands',
  news = 'news',
  oddsComparison = 'bet-finder',
  oddsComparisonExternal = 'bet-finder-external',
  poker = 'poker',
  signup = 'signup',
  terms = 'terms',
  track = 'track',
  unsubscribe = 'unsubscribe',
  user = 'user',
  redirect = 'redirect'
}

const stub = '/:market/:lang?'


export const routes: GCRoute[] = [
  {
    path: "/",
    component: Root,
    routes: [
      {
        path: "/:market/",
        component: MarketRoute,
        routes: [
          {
            path: `${ stub }`,
            component: LangRoute,
            routes: [
              {
                path: `${ stub }/${ pathname.jgEmbed }`,
                name: 'Page.News',
                skipPageFrame: (props: any) => props.loginStatus === userLoginState.loggedOut,
                needsAuth: true,
                isPage: true,
                component: embed,
              },
              {
                path: `${ stub }/${ pathname.login }`,
                component: login,
                name: 'Page.Login',
                needsNoAuth: true,
                isModal: true,
                customModal: loggedOutModal,
                modalParams: { ...defaultModalParams, name: 'Page.Login', noBackdrop: true, customHolderStyles: { 'backgroundColor': '#ffffff' } },
                exact: true
              },
              {
                path: `${ stub }/${ pathname.signup }`,
                name: 'Page.Signup',
                needsNoAuth: true,
                component: signup,
                customModal: loggedOutModal,
                isModal: true,
                modalParams: { ...defaultModalParams, name: 'Page.Signup', noBackdrop: true, customHolderStyles: { 'backgroundColor': '#ffffff' } },
                exact: true
              },
              {
                path: `${ stub }/${ pathname.confirmEmail }`,
                isPage: true,
                customModal: loggedOutModal,
                needsNoAuth: true,
                skipPageFrame: (props: any) => props.loginStatus === userLoginState.loggedOut,
                name: 'Page.ConfirmEmail',
                component: confirmEmail,
                exact: true
              },
              {
                path: `${ stub }/${ pathname.changeEmail }`,
                isModal: true,
                customModal: loggedOutModal,
                needsAuth: true,
                modalParams: { ...defaultModalParams, name: 'Page.ChangeEmail', backButton: true, stack: true },
                name: 'Page.ChangeEmail',
                component: Wip,
                exact: true
              },
              {
                path: `${ stub }/${ pathname.forgottenPassword }`,
                isModal: true,
                needsNoAuth: true,
                name: 'Page.ForgottenPassword',
                customModal: loggedOutModal,
                modalParams: { ...defaultModalParams, name: 'Page.ForgottenPassword', backButton: true, stack: true },
                component: forgottenPassword,
                exact: true
              },
              {
                path: `${ stub }/${ pathname.changePassword }`,
                isModal: true,
                needsAuth: true,
                name: 'Page.ChangePassword',
                modalParams: { ...defaultModalParams, name: 'Page.ChangePassword', backButton: true, stack: true, description: 'ChangePassword.Text.Preamble' },
                component: changePassword,
                exact: true
              },
              {
                path: `${ stub }/${ pathname.terms }`,
                isPage: true,
                needsAuth: false,
                skipPageFrame: (props: any) => props.loginStatus === userLoginState.loggedOut,
                name: 'Page.Terms',
                component: terms,
                exact: true
              },
              {
                path: `${ stub }/${ pathname.track }`,
                name: 'Page.Track',
                isModal: true,
                needsAuth: true,
                modalParams: { ...defaultModalParams, name: 'Page.Track' },
                component: tracker,
                exact: true
              },
              {
                path: `${ stub }/${ pathname.casino }`,
                name: 'Page.Casino',
                skipPageFrame: (props: any) => props.loginStatus === userLoginState.loggedOut,
                needsAuth: true,
                isPage: true,
                component: casino,
              },
              {
                path: `${ stub }/${ pathname.bookmaker }`,
                name: 'Page.Bookmaker',
                skipPageFrame: (props: any) => props.loginStatus === userLoginState.loggedOut,
                needsAuth: true,
                isPage: true,
                component: bookmaker,
              },
              {
                path: `${ stub }/${ pathname.poker }`,
                name: 'Page.Poker',
                skipPageFrame: (props: any) => props.loginStatus === userLoginState.loggedOut,
                needsAuth: true,
                isPage: true,
                component: poker,
              },
              {
                path: `${ stub }/${ pathname.news }`,
                name: 'Page.News',
                skipPageFrame: (props: any) => props.loginStatus === userLoginState.loggedOut,
                needsAuth: true,
                isPage: true,
                component: news,
              },
              {
                path: `${ stub }/${ pathname.myBrands }`,
                name: 'Page.MyBrands',
                component: myBrands,
                isModal: true,
                modalParams: { ...defaultModalParams, name: 'Page.MyBrands', backButton: true, stack: true, description: 'MyBrands.Text.Instructions' },
                needsAuth: true,
                exact: true
              },
              {
                path: `${ stub }/${ pathname.activityHistory }`,
                name: 'Page.History',
                isPage: true,
                component: activityHistory,
                needsAuth: true,
                exact: true
              },
              {
                path: `${ stub }/${ pathname.user }`,
                name: 'Page.User',
                component: user,
                isModal: true,
                needsAuth: true,
                modalParams: { ...defaultModalParams },
                exact: true
              },
              {
                path: `${ stub }/${ pathname.marketLanguage }`,
                name: 'Page.MarketLanguage',
                isModal: true,
                needsAuth: true,
                modalParams: { ...defaultModalParams, backButton: true, name: 'Page.MarketLanguage', description: 'MarketLang.Text.Instructions', stack: true },
                component: marketLanguage,
                exact: true
              },
              {
                path: `${ stub }/${ pathname.communicationPrefs }`,
                name: 'Page.CommunicationPreferences',
                isModal: true,
                needsAuth: true,
                modalParams: { ...defaultModalParams, backButton: true, name: 'Page.CommunicationPreferences', stack: true, description: 'CommunicationPrefs.Text.Instructions' },
                component: communicationPrefs,
                exact: true
              },
              {
                path: `${ stub }/${ pathname.cookie }`,
                name: 'Page.Cookie',
                skipPageFrame: (props: any) => props.loginStatus === userLoginState.loggedOut,
                needsAuth: false,
                isPage: true,
                component: cookie,
                exact: true
              },
              {
                path: `${ stub }/${ pathname.privacyPolicy }`,
                name: 'Page.PrivacyPolicy',
                skipPageFrame: (props: any) => props.loginStatus === userLoginState.loggedOut,
                needsAuth: false,
                isPage: true,
                component: privacyPolicy,
                exact: true
              },
              {
                path: `${ stub }/${ pathname.unsubscribe }`,
                name: 'Page.Unsubscribe',
                skipPageFrame: (props: any) => props.loginStatus === userLoginState.loggedOut,
                needsAuth: false,
                isPage: true,
                component: unsubscribe,
                exact: true
              },
              {
                path: `${ stub }/${ pathname.redirect }`,
                name: 'Page.Redirect',
                skipPageFrame: (props: any) => props.loginStatus === userLoginState.loggedOut,
                needsAuth: false,
                isPage: true,
                component: redirect,
                exact: true
              },
              {
                path: `${ stub }/${ pathname.oddsComparison }/:sport?/:competition?/:event?`,
                name: 'Page.BetFinder',
                isPage: true,
                skipPageFrame: (props: any) => props.loginStatus === userLoginState.loggedOut,
                skipPageFrameHeader: (props: any) => true,
                component: oddsComparison,
                exact: true
              },
              {
                path: `${ stub }/${ pathname.oddsComparisonExternal }/:site/:sport?/:competition?/:event?`,
                name: 'Page.BetFinder',
                isPage: true,
                skipPageFrame: (props: any) => true,
                skipPageFrameHeader: (props: any) => true,
                component: oddsComparisonExternal,
                exact: true
              },
              {
                path: `${ stub }/${ pathname.jackpotTracker }/:slug?/`,
                name: 'Page.JackpotTracker',
                isPage: true,
                skipPageFrame: (props: any) => props.loginStatus === userLoginState.loggedOut,
                skipPageFrameHeader: (props: any) => true,
                component: jackpotTracker,
                exact: true
              },
              {
                path: `${ stub }/${ pathname.jackpotTrackerExternal }/:site/:slug?/`,
                name: 'Page.JackpotTracker',
                isPage: true,
                skipPageFrame: (props: any) => true,
                skipPageFrameHeader: (props: any) => true,
                component: jackpotTrackerExternal,
                exact: true
              },
              {
                path: `${ stub }/`, // Duplicate of above
                name: 'Page.Home',
                isPage: true,
                skipPageFrame: (props: any) => props.loginStatus === userLoginState.loggedOut,
                skipPageFrameHeader: (props: any) => true,
                component: home,
                exact: true
              },
              {
                path: `${ stub }/:notFound`, // Duplicate of above
                name: 'Page.Home',
                isPage: true,
                skipPageFrame: (props: any) => props.loginStatus === userLoginState.loggedOut,
                skipPageFrameHeader: (props: any) => true,
                component: notFound,
                exact: false
              }
            ]
          }
        ]
      }
    ]
  }
]





export type splitTextResult = {
  pre: string,
  link: string,
  post: string
}

export function splitTextForLink(text: string, linkKey: string) {

  const keyLength = linkKey.length + 2 // Allow for the braces
  
  const lls = text.indexOf(`{${linkKey}}`)
  const lle = text.indexOf(`{/${linkKey}}`)
  
  if (lls === -1) return {pre: text, link: '', post:''}


  const llStart = text.substring(0, lls)
  const llMid = text.substring(lls + keyLength, lle)
  const llEnd = lle + keyLength + 1 > text.length ? '' : text.substring(lle + keyLength + 1)

  return {pre: llStart, link: llMid, post: llEnd} as splitTextResult
}
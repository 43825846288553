import clsx from 'clsx'
import GCLink from 'components/ux/GCLink'
import GCTextIcon from 'components/icons/GCTextIcon'
import MenuIcon from 'components/icons/MenuIcon'
import React from 'react'
import { Box, Button, Container, Typography, useTheme } from '@material-ui/core'
import { connect } from 'react-redux'
import { i18nContext } from 'services/i18n/i18n.context'
import { logout, userLoginState } from 'services/auth/auth.slice'
import { useMenuController } from 'components/page/menu/Menu.controller'
import { menuStyles } from 'components/page/menu/MenuTall.styles'
import { RootState } from 'app/store'
import { setSnackbar } from 'components/page/snackbar/snackbar.slice'
import { useHistory } from 'react-router-dom'
import { pathname } from 'app/routing/routes'

const mapStateToProps = (state: RootState) => {
  const { auth, i18n: { market, lang, langLoaded } } = state
  return { ...auth, market, lang, langLoaded }
}

const mapDispatchToProps = {
  setSnackbar, logout
}

function MenuTall(props: any) {

  // *** Init
  const route = props.renderTarget?.route.name

  const i18nCtx = React.useContext(i18nContext)
  const { t } = i18nCtx
  const history = useHistory()

  const controller = useMenuController(
    history
  )

  // Styling
  const theme = useTheme()
  const classes = menuStyles(theme)

  // *** Render
  // Render Functions
  const renderLink = (name: string, pathname: string, itemClass = classes.menuItem) => {

    const selected = route === `Page.${ name }`

    return (
      <Box className={itemClass}>
        <GCLink to={pathname} className={clsx(classes.menuLink, { [classes.selected]: selected })}>
          <Box className={clsx(classes.iconWrapper, { [classes.iconSelected]: selected, [classes.iconNotSelected]: !selected })}>
            <MenuIcon type={name} className={classes.icon} />
          </Box>
          <Typography className={classes.text}>{t(`Page.${ name }`)}</Typography>
        </GCLink>
      </Box>
    )
  }

  const renderDivider = () => {
    return (
      <Box className={classes.menuItem}>
        <hr className={classes.divider} />
      </Box>
    )
  }

  return (
    <Box className={classes.root}>

      <Box className={classes.logoTop}></Box>
      <Box className={classes.logoWrapper}>
        <Box className={classes.logoBackdrop}></Box>
        <Box className={classes.logo}>
          <GCLink to={pathname.home}><GCTextIcon /></GCLink>
        </Box>
      </Box>


      {/* Logged Out */}
      {props.loginStatus === userLoginState.loggedOut &&
        <>
          <Button variant="contained" disabled={route === 'Page.Signup'}
            className={clsx(classes.button, classes.signupButton)} onClick={controller.gotoSignup}>
            {t('Page.Signup')}
          </Button>
          <Button variant="contained" disabled={route === 'Page.Login'}
            className={clsx(classes.button, classes.loginButton)} onClick={controller.gotoLogin}>
            {t('Page.Login')}
          </Button>
        </>
      }

      {/* Logged In */}
      {props.loginStatus === userLoginState.loggedIn &&
        <Box className={classes.menuContainer}>
          {renderLink('Home', pathname.home)}

          {renderDivider()}
          
          {renderLink('MyBrands', pathname.myBrands)}
          {renderLink('JackpotTracker', pathname.jackpotTracker)}
          {renderLink('BetFinder', pathname.oddsComparison)}
          {renderLink('Track', pathname.track)}

          {renderDivider()}

          {renderLink('Casino', pathname.casino)}
          {renderLink('Bookmaker', pathname.bookmaker)}
          {renderLink('Poker', pathname.poker)}

          {renderDivider()}


          {props.market !== 'en' && renderLink('News', pathname.news)}

        </Box>
      }

      <Box className={classes.bigGap}></Box>
      
      <Box className={classes.menuContainerBottom}>
        <Container>
          {props.loginStatus === userLoginState.loggedIn &&
            renderLink('User', pathname.user, classes.menuItemBottom)
          }
        </Container>
      </Box>
    </Box>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(MenuTall)
import React from 'react'

export default function TrackIcon(props: any) {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="action/assessment">
    <path id="Path" d="M7 10H9V17H7V10Z" fill="white"/>
    <path id="Path_2" d="M11 7H13V17H11V7Z" fill="white"/>
    <path id="Path_3" d="M15 12H17V17H15V12Z" fill="white"/>
    <path id="Subtract" fillRule="evenodd" clipRule="evenodd" d="M15 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V15H19V19H5V5H15V3Z" fill="white"/>
    <rect id="Rectangle 1785" x="19" y="3" width="2" height="10" fill="white"/>
    <rect id="Rectangle 1785_2" x="25" y="7" width="2" height="10" transform="rotate(90 25 7)" fill="white"/>
    </g>
    </svg>
  )
}
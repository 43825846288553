import { registerReducer } from "app/store"
import React from "react"
import { extractMarketLangFromPath } from "services/lang/lang.lib"

// === Dynamically imported components (Page level)

export const activityHistory = React.lazy(async () => {
  await Promise.all([getActivityReducer(), getMyBrandsReducer(), getCurrencyReducer()])
  return import(/* webpackChunkName: 'page-activity' */ `pages/activity/Activity.page`)
})

export const bookmaker = React.lazy(async () => {
  await getMyBrandsReducer()
  return import(/* webpackChunkName: 'page-bookmaker' */ `pages/bookmaker/Bookmaker.page`)
})

export const casino = React.lazy(async () => {
  await getMyBrandsReducer()
  return import(/* webpackChunkName: 'page-casino' */ `pages/casino/Casino.page`)
})

export const changePassword = React.lazy(async () => {
  await getChangePasswordReducer()
  return import(/* webpackChunkName: 'page-changePassword' */ `pages/changePassword/ChangePassword.page`)
})


export const communicationPrefs = React.lazy(async () => {
  await getUserReducer()
  return import(/* webpackChunkName: 'page-comms-prefs' */ `pages/account/communicationPrefs/CommunicationPrefs.page`)
})

export const confirmEmail = React.lazy(async () => {
  await getConfirmEmailReducer()
  return import(/* webpackChunkName: 'page-confirmEmail' */ `pages/confirmEmail/ConfirmEmail.page`)
})

export const cookie = React.lazy(async () => {
  const parts = extractMarketLangFromPath(window.location.pathname)
  const lang = parts.language === '' ? parts.marketId : parts.language
  return import(/* webpackChunkName: 'page-[request]' */ `pages/cookie/${ lang }Cookie`)
})

export const embed = React.lazy(async () => {
  return import(/* webpackChunkName: 'page-embed' */ `pages/embed/Embed.page`)
})

export const oddsComparison = React.lazy(async () => {
  await getOddsReducer()
  return import(/* webpackChunkName: 'page-oddsComparison' */ `pages/oddsComparison/OddsComparison.page`)
})

export const oddsComparisonExternal = React.lazy(async () => {
  await getOddsReducer()
  return import(/* webpackChunkName: 'view-oddsComparisonExternal' */ `pages/oddsComparison/containers/oddsContainerExternal/OddsContainerExternal.component`)
})

export const jackpotTracker = React.lazy(async () => {
  await Promise.all([ getMyBrandsReducer(), getJackpotReducer()])
  return import(/* webpackChunkName: 'page-jackpot-tracker' */ `pages/jackpot/Jackpot.page`)
})

export const jackpotTrackerExternal = React.lazy(async () => {
  await Promise.all([ getMyBrandsReducer(), getJackpotReducer()])
  return import(/* webpackChunkName: 'view-jackpotTrackerExternal' */ `pages/jackpot/containers/jackpotContainerExternal/JackpotContainerExternal.component`)
})

export const privacyPolicy = React.lazy(async () => {
  const parts = extractMarketLangFromPath(window.location.pathname)
  const lang = parts.language === '' ? parts.marketId : parts.language
  return import(/* webpackChunkName: 'page-[request]' */ `pages/privacyPolicy/${ lang }PrivacyPolicy`)
})

export const forgottenPassword = React.lazy(async () => {
  await getForgottenPasswordReducer()
  return import(/* webpackChunkName: 'page-forgottenPassword' */ `pages/forgottenPassword/ForgottenPassword.page`)
})


export const terms = React.lazy(async () => {
  const parts = extractMarketLangFromPath(window.location.pathname)
  const lang = parts.language === '' ? parts.marketId : parts.language
  return import(/* webpackChunkName: 'page-[request]' */ `pages/terms/${ lang }Terms`)
})

export const home = React.lazy(async () => {
  await Promise.all([getTrackerReducer(), getMyBrandsReducer(), getCurrencyReducer()])
  return import(/* webpackChunkName: 'page-home' */ `pages/home/Home.page`)
})

export const login = React.lazy(async () => {
  await Promise.all([getLoginReducer(), getEmailVerificationReducer()])
  return import(/* webpackChunkName: 'page-login' */ `pages/login/Login.page`)
})

export const marketLanguage = React.lazy(async () => {
  return import(/* webpackChunkName: 'page-market-language' */ `pages/account/marketLang/MarketLang.page`)
})

export const myBrands = React.lazy(async () => {
  await getMyBrandsReducer()
  return import(/* webpackChunkName: 'page-my-brands' */ `pages/myBrands/MyBrands.page`)
})

export const news = React.lazy(async () => {
  await getMyBrandsReducer()
  return import(/* webpackChunkName: 'page-news' */ `pages/news/News.page`)
})

export const notFound = React.lazy(async () => {
  return import(/* webpackChunkName: 'page-not-found' */ `components/routing/notFound/notFound.component`)
})

export const poker = React.lazy(async () => {
  await getMyBrandsReducer()
  return import(/* webpackChunkName: 'page-poker' */ `pages/poker/Poker.page`)
})


export const redirect = React.lazy(async () => {
  return import(/* webpackChunkName: 'page-redirect' */ `pages/redirect/Redirect.page`)
})

export const signup = React.lazy(async () => {
  await Promise.all([getSignupReducer(), getEmailVerificationReducer()])
  return import(/* webpackChunkName: 'page-signup' */ `pages/signup/Signup.page`)
})

export const tracker = React.lazy(async () => {
  await Promise.all([getTrackerReducer(), getMyBrandsReducer(), getCurrencyReducer()])
  return import(/* webpackChunkName: 'page-tracker' */ `pages/tracker/Tracker.page`)
})

export const unsubscribe = React.lazy(async () => {
  await getUnsubscribeReducer()
  return import(/* webpackChunkName: 'page-user' */ `pages/unsubscribe/Unsubscribe.page`)
})

export const user = React.lazy(async () => {
  return import(/* webpackChunkName: 'page-user' */ `pages/user/User.page`)
})


// === Custom Modals

export const loggedOutModal = React.lazy(async () => {
  return import(/* webpackChunkName: 'modal-loggedOut' */ `components/page/modal/custom/loggedOut/LoggedOutModal.component`)
})


// === Reducers

const getActivityReducer = async () => {
  const { default: red } = await import(/* webpackChunkName: 'slice-activity' */ `reducers/activity.slice`)
  registerReducer({ activity: red })
}

const getChangePasswordReducer = async () => {
  const { default: red } = await import(/* webpackChunkName: 'slice-changePassword' */ `reducers/changePassword.slice`)
  registerReducer({ changePassword: red })
}

const getConfirmEmailReducer = async () => {
  const { default: red } = await import(/* webpackChunkName: 'slice-confirmEmail' */ `reducers/confirmEmail.slice`)
  registerReducer({ confirmEmail: red })
}

const getCurrencyReducer = async () => {
  const { default: red } = await import(/* webpackChunkName: 'slice-currency' */ `reducers/currency.slice`)
  registerReducer({ currency: red })
}

const getEmailVerificationReducer = async () => {
  const { default: red } = await import(/* webpackChunkName: 'slice-emailVerification' */ `reducers/emailVerification.slice`)
  registerReducer({ emailVerification: red })
}

const getForgottenPasswordReducer = async () => {
  const { default: red } = await import(/* webpackChunkName: 'slice-forgottenPassword' */ `reducers/forgottenPassword.slice`)
  registerReducer({ forgottenPassword: red })
}

const getLoginReducer = async () => {
  const { default: red } = await import(/* webpackChunkName: 'slice-login' */ `reducers/login.slice`)
  registerReducer({ login: red })
}

const getMyBrandsReducer = async () => {
  const { default: red } = await import(/* webpackChunkName: 'slice-myBrands' */ `reducers/myBrands.slice`)
  registerReducer({ myBrands: red })
}

const getSignupReducer = async () => {
  const { default: red } = await import(/* webpackChunkName: 'slice-signup' */ `reducers/signup.slice`)
  registerReducer({ signup: red })
}

const getTrackerReducer = async () => {
  const { default: red } = await import(/* webpackChunkName: 'slice-tracker' */ `reducers/tracker.slice`)
  registerReducer({ tracker: red })
}

const getUnsubscribeReducer = async () => {
  const { default: red } = await import(/* webpackChunkName: 'slice-unsubscribe' */ `reducers/unsubscribe.slice`)
  registerReducer({ unsubscribe: red })
}

const getUserReducer = async () => {
  const { default: red } = await import(/* webpackChunkName: 'slice-user' */ `reducers/user.slice`)
  registerReducer({ user: red })
}

const getOddsReducer = async () => {
  const { default: red } = await import(/* webpackChunkName: 'slice-odds' */ `reducers/odds.slice`)
  registerReducer({ odds: red })
}

const getJackpotReducer = async () => {
  const { default: red } = await import(/* webpackChunkName: 'slice-jackpot' */ `reducers/jackpot.slice`)
  registerReducer({ jackpot: red })
}
import {createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState = {
  lastChangedTimestamp: 0,
}

export type storageState = typeof initialState


export const storageSlice = createSlice({
  name: 'storage',
  initialState,
  reducers: {
    setStorageChanged: (state, action: PayloadAction<number>) => {
      state.lastChangedTimestamp = action.payload
    }
  }
})

export const { setStorageChanged } = storageSlice.actions;

export default storageSlice.reducer;

import { makeStyles } from '@material-ui/core'

export const menuTopHeight = 60

export const menuWideTopStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    spacing: 0,
    padding: 0,
    margin: 0,
    height: '100%',
    backgroundColor: '#253146'
  },
  logoContainer: {
    height: 60,
    width: '100%',
    textAlign: 'center'
  },
  logo: {
    height: 40,
    marginTop: 10
  },
  login: {
    position: 'absolute',
    top: 20,
    right: 20,  
    zIndex: 1
  },
  loginText: {
    color: '#FFF',
  }
}))
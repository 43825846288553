import { apiCheckLoggedIn } from 'services/api/access.checkLoggedIn'
import { logout, userLogoutReason } from 'services/auth/auth.slice'
import { store } from 'app/store'
import { onLogout } from 'services/auth/auth.hook'

const sessionLength = 1000 * 60 * 60 * 8 // 8 hours

const keepAliveFrequency = 1000 * 60 // 1 minute
const keepAliveJitter = 1000 * 10 // 10 seconds

let sessionTimeout: NodeJS.Timeout
let keepAlive: NodeJS.Timeout

export function resetSessionTimeout() {
  // console.info(`Session -- ResetSessionTimeout`)
  clearSessionTimeout()
  sessionTimeout = setTimeout(onSessionTimeout, sessionLength)
}

export function clearSessionTimeout() {
  // console.info(`Session -- ClearSessionTimeout`)
  if (sessionTimeout !== undefined) clearTimeout(sessionTimeout)
}

export function startKeepAlive() {
  // console.info(`Session -- StartKeepAlive`)
  keepAlive = setTimeout(onKeepAlive, keepAliveFrequency + (Math.random() * keepAliveJitter) - (keepAliveJitter / 2) )
}

export function stopKeepAlive() {
  // console.info(`Session -- StopKeepAlive`)
  clearTimeout(keepAlive)
}

async function onKeepAlive() {
  // console.info(`Session -- OnKeepAlive`)
  const result = await apiCheckLoggedIn()
  if (!result.isSuccess) {
    store.dispatch(logout({reason: userLogoutReason.timeOut}))
    onLogout()
  } else {
    startKeepAlive()
  }
}

function onSessionTimeout() {
  // console.info(`Session --OnSessionTimeout`)
  store.dispatch(logout({reason: userLogoutReason.timeOut}))
}
import { RouteConfig } from 'react-router-config'
import { userLoginState, userLogoutReason } from './auth.slice'

export function needsLoginRedirect(
  renderTarget: RouteConfig | undefined,
  prevLoginState: userLoginState,
  loginStatus: userLoginState,
  logoutReason: userLogoutReason,
  history: any
) {

  if (!renderTarget) return false
  if (loginStatus !== userLoginState.loggedOut) return false

  const authedRoute = renderTarget?.route.needsAuth || (renderTarget?.route.name === "Page.Home" && history.action !== 'POP')
  const loggedOut = loginStatus === userLoginState.loggedOut
  const previouslyLoggedIn = prevLoginState === userLoginState.loggedIn && logoutReason !== userLogoutReason.notSet
  const isBrowserNav = history.action === 'POP'

  if (authedRoute && loggedOut && (previouslyLoggedIn || isBrowserNav)) return true
  return false

}
import authReducer from 'services/auth/auth.slice'
import i18nReducer from 'services/i18n/i18n.slice'
import loginReducer from 'reducers/login.slice'
import modalReducer from 'reducers/modal.slice'
import refreshReducer from 'reducers/refresh.slice'
import snackbarReducer from 'components/page/snackbar/snackbar.slice'
import storageReducer from 'services/storage/storage.slice'
import termsReducer from 'reducers/terms.slice'
import themeReducer from 'services/theme/theme.slice'
import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit'
import { combineReducers, Store } from 'redux'
import { ReducerMap, StoreShape } from 'app/storeTypes.def'

let reducerMap: ReducerMap = {
  auth: authReducer,
  theme: themeReducer,
  i18n: i18nReducer,
  snackbar: snackbarReducer,
  terms: termsReducer,
  modal: modalReducer,
  storage: storageReducer,
  refresh: refreshReducer,
  login: loginReducer
}

export const store = configureStore({reducer: reducerMap});

export function getStore() {
  return store;
}

export function registerReducer(newReducers: ReducerMap): Store<StoreShape> {
  reducerMap = { ...reducerMap, ...newReducers };
  // We probably want to validate we're not replacing reducers that already
  // exist here but that exercise is left up to the reader.
  store.replaceReducer(combineReducers(reducerMap));
  return store;
}

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>

import { createSlice } from '@reduxjs/toolkit'
import { RootState } from 'app/store'

import lightTheme from './Theme.light.styles'
import darkTheme from './Theme.dark.styles'

export enum themes{
  Dark,
  Light
}

const initialState = {
  theme: themes.Light,
}

export type ThemeState = typeof initialState

export const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    setDark: state => {
      state.theme = themes.Dark
    },
    setLight: state => {
      state.theme = themes.Light
    }
  }
})

export const { setDark, setLight } = themeSlice.actions;

export const selectTheme : any = (state: RootState) => {
  if (!state || !state.theme) return darkTheme

  switch (state.theme.theme){
    case themes.Light:
      return lightTheme;
    default:
      return darkTheme;
  }
}

export default themeSlice.reducer;

import { makeStyles } from "@material-ui/core";

export const useLoaderStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      width: '100%',
      height: '100%',
      alignItems: 'center',
      justifyContent: 'center',
      margin: 'auto',
      backgroundColor: '#314C6A',
      color: theme.palette.common.black
    },
    icon: {
      marginLeft: 16,
      marginRight: 16,
      width: '20%',
      maxwidth: 200
    },
  }));
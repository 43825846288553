export const config = () => {
    switch (PLATFORM) {
        case 'Stage':
            return configStage
        case 'Prod':
            return configProd
        default:
            return configDev
    }
}

const configDev = {
    api: {
        baseUrl: 'http://localhost:5000/api/v1/',
        redirectUri: 'http://localhost:3000/redirect',
        socketUri: 'http://localhost:5000/hub/v1',
        authClientId: '361392863428-6le667je1l9ih3djc3rrpgnuljtilu4n.apps.googleusercontent.com'
    }
}

const configStage = {
    api: {
        baseUrl: 'https://stagingapi.gamblersconcierge.com/api/v1/',
        redirectUri: 'https://staging.gamblersconcierge.com/redirect',
        socketUri: 'https://stagingapi.gamblersconcierge.com/hub/v1',
        authClientId: '361392863428-6le667je1l9ih3djc3rrpgnuljtilu4n.apps.googleusercontent.com'
    }
}

const configProd = {
    api: {
        baseUrl: 'https://api.gamblersconcierge.com/api/v1/',
        redirectUri: 'https://gamblersconcierge.com/redirect',
        socketUri: 'https://api.gamblersconcierge.com/hub/v1',
        authClientId: '361392863428-6le667je1l9ih3djc3rrpgnuljtilu4n.apps.googleusercontent.com'
    }
}

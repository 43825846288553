import React from 'react'
import { Link } from '@material-ui/core'
import { Link as RouterLink } from 'react-router-dom'
import { extractMarketLangFromPath } from 'services/lang/lang.lib'

export interface GCLinkProps {
    to?: string,
    [propName: string]: any
}

/**
 * Create HTML Links
 * Using a 'to' paramater wraps a React Router component
 * @export
 * @param {GCLinkProps} props
 * @return {*} 
 */
export default function GCLink(props: GCLinkProps) {
    // Props tidy up
    const {children} = props
    const componentProps: any = {...props}
    if (children) delete componentProps.children

    // if external link
    if (props.to && props.to.toLowerCase().startsWith('http')) {
        if (props.href === undefined) {
            componentProps.href = props.to
        }
      delete componentProps.to
    }

    if (componentProps.to === undefined) {
        return (<Link style={{cursor: 'pointer'}} target='_blank' {...componentProps}>{children}</Link >)
    }

    delete componentProps.to
    return GCLinkRouted(props.to!, componentProps, children)

}

function GCLinkRouted(to: string, props: any, children: any) {
    const parts = extractMarketLangFromPath(window.location.pathname)
    const redirectRoute = parts.language === parts.marketId || parts.language === ''
        ? `/${ parts.marketId }` 
        : `/${ parts.marketId }/${ parts.language }`

    to = to.startsWith('/') 
        ? `${redirectRoute}${to}`
        : `${redirectRoute}/${to}`

    const linkComponent = React.useMemo(
        () =>
            React.forwardRef((linkProps, ref) => (
                <RouterLink to={to} {...linkProps} />
            )),
        [to],
    )

    return (
        <Link component={linkComponent} {...props}>{children}</Link >
    )
}

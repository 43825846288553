import { axiosAuthInstance } from "services/http/http.context"
import { i18n } from "services/i18n/i18n.context"
import { resetSessionTimeout } from "services/session/session"
import { resultWrapper } from "./resultWrapper"

export class UncachedRequest {

  public cycleSessionTimeout = true

  public async post(endpoint: string, args: any) {

    // If we're doing something initiated by the user -- reset the session timeout 
    if (this.cycleSessionTimeout) resetSessionTimeout()

    try {
      const response = await axiosAuthInstance.post(endpoint, args)

      // If not ok
      if (response.status !== 200) {
        const isSuccess = false
        const message = i18n(response.statusText as string)
        const data = response.data
        return { isSuccess, message, data } as resultWrapper
      } else {
        const isSuccess = true
        const message = 'OK'
        const data = response.data
        return { isSuccess, message, data } as resultWrapper
      }

    } catch (err) {
      const isSuccess = false
      const message = err.response?.data ?? err.message
      const data = undefined
      return { isSuccess, message, data } as resultWrapper
    }

  }

  public async get(endpoint: string, key = 'none') {
  
    // If we're doing something initiated by the user -- reset the session timeout 
    if (this.cycleSessionTimeout) resetSessionTimeout()

    try {
      const response = await axiosAuthInstance.get(endpoint)
      // If not ok
      if (response.status !== 200) {
        const isSuccess = false
        const message = i18n(response.statusText as string)
        const data = response.data
        return { isSuccess, message, data } as resultWrapper
      } else {
        const isSuccess = true
        const message = 'OK'
        const data = response.data
        return { isSuccess, message, data } as resultWrapper
      }

    } catch (err) {
      const isSuccess = false
      const message = err.response?.data ?? err.message
      const data = undefined
      return { isSuccess, message, data } as resultWrapper
    }
  }

}
import { makeStyles } from '@material-ui/core'

export const modalHeaderHeight = 60 

export const modalStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    width: '100%',
    backgroundColor: '#ffffff',
    boxShadow: '10px 0 10px rgba(0,0,0,0.06)',
    position: 'relative',
    background: 'linear-gradient(#f4f7fc, #f8f8f8)',
    overflow: 'hidden' 
  },
  backdrop: {
    position: 'absolute',
    top: -225,
    left: -97,
    height: 340,
    width: 1000,
    background: '#E2EDF8',
    transform: 'rotate(-15deg)',
  },
  modalHolder: {
    position: 'relative',
    padding: 0,
    margin: 0,
    width: '100%',
    height: '100%',
    zIndex: 2,
    color: '#2E3A59',
    display: 'flex',
    flexDirection: 'column'
  },
  header: {
  },
  modalNavigation: {
    position: 'relative',
    height: modalHeaderHeight,
    opacity: 0.8,
  },
  back: {
    position: 'absolute',
    width: 40,
    top: 24,
    right: 70,
    '&:hover': {
      cursor: 'pointer',
      color: '#1c2438',
    },
  },
  backIcon: {
  },
  close: {
    position: 'absolute',
    width: 40,
    top: 24,
    right: 16,
    '&:hover': {
      cursor: 'pointer',
      color: '#1c2438',
    },
  },
  closeIcon: {
  },
  title: {
    marginLeft: 24,
    marginTop: 16,
    marginBottom: 24,
    textAlign: 'left',
    color: '#242440',
    fontSize: 18,
    fontWeight: 'bold',
  },
  content: {
    width: '100%',
    height: `calc(100% - ${modalHeaderHeight}px)`,
    maxHeight: `calc(100% - ${modalHeaderHeight}px)`,
    zIndex: 2,
    transform:'rotate(0.0deg)', // zIndex hack
  },
  description:{ // Steve -- currently commented out in markup
    position: 'relative',
    marginLeft: 30,
    marginRight: 30,
    marginTop: 0,
    marginBottom: 24,
    fontSize: 16,
  }
}))

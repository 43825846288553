import React from "react";
import { store } from 'app/store'
import { setStorageChanged } from "./storage.slice";


export class storageState {
    public userName: string | undefined
    public market: string | undefined
    public lang: string | undefined
    public lastCurrency: string | undefined
    public lastBrand: string | undefined
    public lastLoginMethod: string | undefined
    public acceptCookies: string | undefined
}

export class storage {
    private storageKey = 'gcState' 
        
    get state(): storageState {
        const localState = localStorage.getItem(this.storageKey)
        if (!localState) return new storageState()
        return JSON.parse(localState) as storageState 
    }

    set state(newState: storageState) {
        localStorage.setItem(this.storageKey, JSON.stringify(newState))
        store.dispatch(setStorageChanged(Date.now()))
    }

    public clear() {
        localStorage.removeItem(this.storageKey)
    }
}

export const defaultStorage = new storage()

export const StorageContext = React.createContext(
    defaultStorage
);


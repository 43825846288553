import { resultWrapper } from 'services/api/base/resultWrapper'
import { startKeepAlive, clearSessionTimeout, stopKeepAlive, resetSessionTimeout } from 'services/session/session'

/* 
  Global hooks for authentication events
*/

export function onLogin(result?: resultWrapper) {

  // Begin the ping
  startKeepAlive()

  // Begin the Session
  resetSessionTimeout()
}

export function onLogout(result?: resultWrapper) {
  // Stop the ping
  stopKeepAlive()
  
  // End the Session timeout
  clearSessionTimeout()
}

export function onSignup(result?: resultWrapper) {

}

import React from 'react'

export default function HomeIcon(props: any) {
  return (
    <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 9.58579L9.293 0.292786C9.6835 -0.0975955 10.3165 -0.0975955 10.707 0.292786L20 9.58579H18V17.5858C18 18.1381 17.5523 18.5858 17 18.5858H12V11.5858H8V18.5858H3C2.44772 18.5858 2 18.1381 2 17.5858V9.58579H0Z" fill="url(#paint0_linear)"/>
      <defs>
      <linearGradient id="paint0_linear" x1="7.71354" y1="-2.12936e-08" x2="18.5101" y2="14.3165" gradientUnits="userSpaceOnUse">
      <stop stopColor="#F2F7FF"/>
      <stop offset="1" stopColor="white"/>
      </linearGradient>
      </defs>
    </svg>
  )
}
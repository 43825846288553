import tinyI18n from 'tiny-i18n'
import { apiGetLang } from 'services/api/translation.getLang'
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { marketLang } from 'reducers/myBrands.slice'
import { RootState } from 'app/store'

const initialState = {
  lang: '',
  market: '',
  langLoaded: false,
  langLoadedTimestamp: 0,
  lastMessage: '',
  lastError: ''
}

export type I18nState = typeof initialState

interface ILoadLanguageParameters {
  language: string
}

const { setDictionary, setLanguage } = tinyI18n
const langMap = new Map();

const hasLangMap = (lang: string): boolean => {
  return langMap.has(lang);
}

const getLangMap = (lang: string): any => {
  return langMap.get(lang)
}

const setLangMap = (lang: string, value: any): any => {
  return langMap.set(lang, value)
}

export const loadLang = createAsyncThunk(
  'i18n/loadLang',
  async (args: ILoadLanguageParameters, thunkApi) => {
    try {
      const { dispatch, getState } = thunkApi
      const state = getState() as RootState
      const lang = args.language
      // See if we've already loaded this language
      if (state.i18n.lang === lang) return true

      // If the language is cached:
      if (hasLangMap(lang)) {
        const cachedLang = getLangMap(lang)
        setDictionary(cachedLang, lang)
        setLanguage(lang)
        dispatch(setLang(lang))
        dispatch(setLangLoaded(true))
        return true
      }

      // Otherwise import it
      var importLang = await apiGetLang(lang)
      if (!importLang.isSuccess) return thunkApi.rejectWithValue(importLang.data ?? importLang.message)

      const translations = importLang.data
      setDictionary(translations, lang)
      setLanguage(lang)
      setLangMap(lang, translations)
      dispatch(setLang(lang))
      dispatch(setLangLoaded(true))
      return true
    } catch (err) {
      return thunkApi.rejectWithValue(err.message)
    }
  }
)

export const i18nSlice = createSlice({
  name: 'i18n',
  initialState,
  reducers: {
    setLang: (state, action: PayloadAction<string>) => {
      state.lang = action.payload
    },
    setLangLoaded: (state, action: PayloadAction<boolean>) => {
      state.langLoaded = action.payload
    },
    setMarket: (state, action: PayloadAction<string>) => {
      state.market = action.payload
    },
    setMarketLang: (state, action: PayloadAction<marketLang>) => {
      state.market = action.payload.marketId
      state.lang = action.payload.language
    }
  },
  extraReducers: builder => {
    builder
      .addCase(loadLang.pending, (state, action) => {
        state.lastMessage = ''
        state.lastError = ''
        state.langLoaded = false
      })
      .addCase(loadLang.fulfilled, (state, action) => {
        state.langLoadedTimestamp = Date.now()
        state.lastMessage = "Lang.Load.Success"
        // state.langLoaded = true
      })
      .addCase(loadLang.rejected, (state, action) => {
        state.langLoaded = false
        state.lastMessage = action.payload as string
        state.lastError = action.error.message as string
      })
  }
})

export const { setLang, setLangLoaded, setMarket, setMarketLang } = i18nSlice.actions;

export const selectLang = (state: RootState): string => state.i18n.lang!
export const selectMarket = (state: RootState): string => state.i18n.market!
export const selectLangLoaded = (state: RootState): boolean => state.i18n.langLoaded

export default i18nSlice.reducer;

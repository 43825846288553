import {createSlice, PayloadAction } from '@reduxjs/toolkit'
import { modalParams } from 'app/routing/routes'

export type paramsForModal = {
  name: string
  params: modalParams
  location: any
}

const initialState = {
  modals: [] as paramsForModal[],
  fullscreenModal: false
}

export type ModalState = typeof initialState

export const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    addModal: (state, action: PayloadAction<paramsForModal>) => {
      state.modals.unshift(action.payload)
    },
    removeModal: (state, action: PayloadAction<string>) => {
      const idx = state.modals.findIndex(t => t.name === action.payload)
      state.modals.splice(idx,1)
    },
    nextModal: (state) => {
      state.modals.splice(0,1)
    },
    clearAllModals: (state) => {
      state.modals = []
    },
    setFullscreenModal: (state, action: PayloadAction<boolean>) => {
      state.fullscreenModal = action.payload
    }
  }
})

export const { addModal, removeModal, nextModal, clearAllModals, setFullscreenModal } = modalSlice.actions;

export default modalSlice.reducer;

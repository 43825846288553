import { ActionCreatorWithoutPayload } from '@reduxjs/toolkit'
import { GCRoute } from 'app/routing/routes'
import { rebaseMarketLang } from 'services/routing/route.lib'

export class pageController {

  public constructor(
    private history: any,
    private modals: GCRoute[],
    private nextModal: ActionCreatorWithoutPayload<string>,
    private pageTarget: GCRoute,

  ) { }

  public closeModal = () => {
    if (this.modals[0].modalParams?.closeOutside !== true) return
    const route = rebaseMarketLang(this.pageTarget.match.url, this.pageTarget.match.params)
    this.nextModal()
    this.history.push(route)
  }
}
import {createSlice} from '@reduxjs/toolkit'

const initialState = {
  transactionsRefresh: 0
}

export type RefreshState = typeof initialState

export const refreshSlice = createSlice({
  name: 'refresh',
  initialState,
  reducers: {
    refreshTransactions: (state) => {
        state.transactionsRefresh = state.transactionsRefresh + 1
    },
  }
})

export const refreshActions = refreshSlice.actions

export default refreshSlice.reducer;

import React from 'react'
import { renderRoutes } from 'react-router-config'
import { Switch } from 'react-router-dom'

/**
 * The LangRoute only acts as an optional placeholder in the url structure
 *
 * @param {*} props
 * @return {*} 
 */
function LangRoute(props: any) {
  return (
    <>
      <Switch>
        {renderRoutes(props.route.routes, {pageTarget: props.pageTarget, renderTarget: props.renderTarget})}
      </Switch>
    </>
  )
}

export default LangRoute
import { makeStyles } from "@material-ui/core"

export const menuLeftWidth = 245

export const menuStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    position: 'relative',
    top: 0,
    spacing: 0,
    padding: 0,
    margin: 0,
    height: '100%',
    backgroundColor: '#f6f8fc',
    overflow: 'hidden',
    boxShadow: '10px 0 10px rgba(0,0,0,0.05)',
  },
  logoWrapper: {
    position: 'relative',
    width: '100%',
    height: 180,
    textAlign: 'center',
    overflow: 'hidden',
  },
  logoTop: {
    flexGrow: 20,
    flexShrink: 2,
    maxHeight: 32,
    background: '#222222',
  },
  logo: {
    position: 'relative',
    display: 'inline-block',
    marginLeft: 16,
    marginRight: 16,
    flexGrow: 20,
    flexShrink: 2,
    maxHeight: 75,
    width: 80,
    zIndex: 1,
  },
  logoBackdrop: {
    position: 'absolute',
    top: -154,
    left: -57,
    height: 290,
    width: 320,
    background: '#222222',
    transform: 'rotate(-15deg)',
  },
  menuContainer: {
    flexGrow: 20,
    flexShrink: 2,
  },
  bigGap: {
    flexGrow: 20,
    flexShrink: 2
  },
  menuItem: {
    padding: 2,
    margin: '8px 0px 8px 24px',
    flexGrow: 0,
  },
  menuContainerBottom: {
    flexGrow: 0,
    flexShrink: 0,
    marginBottom: 40,
  },
  menuItemBottom: {
    padding: 2,
    marginLeft: 24
  },
  menuLink: {
    color: '#0D2950',
    display: 'block',
    '&:hover': {
      textDecoration: 'none',
    }
  },
  divider: {
   // transform: 'rotate(-15deg)',
    width: 180,
    position: 'relative',
    left: 0,
    top: 0,
    borderTop: '1px solid #D0DDF1',
    margin: '20px 0',
  },
  selected: {
    color: theme.palette.primary.main,
  },
  iconSelected: {
    background: 'linear-gradient(0deg, #d54699 0%, #f36d21 100%)'
  },
  iconNotSelected: {
    background: '#222222'
  },
  iconWrapper: {
    display: 'inline-flex',
    width: 32,
    height: 32,
    borderRadius: 8,
    textAlign: 'center',
    lineHeight: '50px',
    marginRight: 16,
    justifyContent: 'center',
    alignItems: 'center',
    '& svg': {
      transform: 'scale(0.8)'
    }, 
  },
  icon: {
    display: 'inline-flex',
    height: 21,
    color: '#ffffff',
  },
  text: {
    display: 'inline-block',
    fontSize: 14,
    position: 'relative',
    top: '-4px'

  },
  button: {
    marginLeft: 6,
    marginRight: 6,
    marginTop: 10,
    backgroundColor: theme.palette.primary.main,
    fontWeight: 800,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,
    }
  },
  signupButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  loginButton: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.secondary.dark,
    }
  }
}))

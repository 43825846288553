import axios from 'axios'
import { config } from 'app/app.config'
import { logout, userLogoutReason } from 'services/auth/auth.slice'
import { store } from 'app/store'
import { endpoint } from 'services/api/endpoints/def';
import { onLogout } from 'services/auth/auth.hook';

export const axiosAuthInstance = axios.create({
  baseURL: config().api.baseUrl,
  headers: {
    'Content-Type': 'application/json',
  },
  timeout: 200000000,
  withCredentials: true
});

export const axiosNoAuthInstance = axios.create({
  baseURL: config().api.baseUrl,
  headers: {
    'Content-Type': 'application/json',
  },
  timeout: 2000,
  withCredentials: true
});

// axiosAuthInstance.interceptors.request.use(req => {
//   req.headers.authorization = `Bearer ${selectToken(store.getState())}`
//   return req;
// });

axiosAuthInstance.interceptors.response.use(res => {
  return res
}
  , async err => {
    if (!err.response) return Promise.reject(err)

    if (err.response.status !== 401) return Promise.reject(err)
    const originalRequest = err.config
    // var data = {token: `${selectToken(store.getState())}`}
    try {
      const response = await axiosNoAuthInstance.post(endpoint.refreshToken)
      if (response.status !== 200) {
        store.dispatch(logout({reason: userLogoutReason.timeOut}))
        onLogout()
        return Promise.reject(response)
      }
      return axiosAuthInstance(originalRequest)
    } catch (e) {
      return Promise.reject(err)
    }
  })
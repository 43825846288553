import { Snackbar, } from '@material-ui/core'
import { RootState } from 'app/store'
import React from 'react';
import { connect } from 'react-redux';
import { nextSnackBar } from 'components/page/snackbar/snackbar.slice'
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props: any) {
    return <MuiAlert elevation={6} variant="filled" {...props} />
}

const mapDispatchToProps = {
    nextSnackBar
}

const mapStateToProps = (state: RootState) => {
    const { snackbar } = state
    if (snackbar.snacks.length > 0)
        return { ...snackbar.snacks[0] }
    return { content: '' }
}


function AppSnackbar(props: any) {

    const onClose = () => {
        props.nextSnackBar()
    }

    return (
        <>
            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={props.content.length > 0} onClose={onClose} autoHideDuration={props.autoHideDuration} >
                <Alert onClose={onClose} severity={props.severity}>
                    {props.content}
                </Alert>
            </Snackbar>
        </>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(AppSnackbar)
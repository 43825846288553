import React from 'react'

export default function NewsIcon(props: any) {
  return (
    <svg width="25" height="21" viewBox="0 0 25 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.5 5H3.5C2.39543 5 1.5 5.89439 1.5 6.99896C1.5 9.5989 1.5 13.1506 1.5 15C1.5 18 3.5 19.5 5.5 19.5C7.5 19.5 17 19.5 19.5 19.5C22 19.5 23.5 18.5 23.5 15C23.5 12.7123 23.5 7.04235 23.5 3.49882C23.5 2.39425 22.6046 1.5 21.5 1.5L8.5 1.5C7.39543 1.5 6.5 2.39543 6.5 3.5V15" stroke="white" stroke-width="2" stroke-linecap="round"/>
      <rect x="10" y="6" width="10" height="2" rx="1" fill="white"/>
      <rect x="10" y="10" width="10" height="2" rx="1" fill="white"/>
    </svg>
  )
}
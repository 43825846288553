import { makeStyles } from "@material-ui/core";

export const cookieUseStyles = makeStyles((theme) => ({
  root: {
    bottom: 0,
    width: 500, 
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 24,
    margin: '0 auto',
    backgroundColor: '#fff',
    color: '#212121',
    boxShadow: '0px 0px 33px rgba(137, 75, 30, 0.2)',
    borderRadius: '24px 24px 0 0',
    textAlign: 'center',
    fontSize: 14,
    maxWidth: '100%',
    pointerEvents: 'all'
  },
  text: {
    display: 'inline-block',
    alignSelf: 'center',
  },
  textHolder: {
  },
  cookieLink: {
    color: '#212121',
    textDecoration: 'underline',
  },
  buttonHolder: {
    display: 'inline-block',
    marginLeft: 20
  },
  ok: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    }
  }
}))
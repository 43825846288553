import DateFnsUtils from '@date-io/date-fns'
import Page from 'components/page/page/Page.component'
import React, { Suspense, useEffect } from 'react'
import { baseRedirect, validateMarket } from 'services/lang/lang.lib'
import { connect } from 'react-redux'
import { loadLang, setLang, setMarket } from 'services/i18n/i18n.slice'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { renderRoutes } from 'react-router-config'
import { RootState } from 'app/store'
import Loader from 'components/loader/Loader'
import { Switch } from 'react-router-dom'

const mapDispatchToProps = { loadLang, setMarket, setLang }

const mapStateToProps = (state: RootState) => {
  const { i18n: { lang, market }, auth } = state
  return { lang, market, ...auth }
}

/**
 * The MarketRoute component handles:
 * 
 * - Redirection, if there is no market in the URL
 * - Ensuring the market in the URL is valid
 * - Ensuring the language is valid for the selected market
 * - Loading the language
 *
 * @param {*} props
 * @return {*} 
 */
function MarketRoute(props: any) {

  const {location: {pathname}, history, market, lang, setMarket, loadLang, setLang} = props
  const {params} = props.renderTarget.match

  useEffect(() => {
    let red = false

    if (pathname === '/' && history.action === 'POP') red = true
    if (pathname.length < 4) red = true

    // Handles redirecting the page if required
    if (red) {
      baseRedirect(history)
    } else {

      // ensure market is valid
      const reqMarket = params.market
      if (reqMarket !== market) {
        if (!validateMarket(reqMarket!)) {
          baseRedirect(history)
          return
        }
        setMarket(reqMarket)
      }

      // recalc required lang
      let reqLang = params.lang || reqMarket
      if (reqLang.length > 2) reqLang = reqMarket

      // Load (if changed)
      if (reqLang !== lang) {
        loadLang({ language: reqLang })
        setLang(reqLang)
      }
    }

  }, [pathname, history, market, lang, params, setMarket, loadLang, setLang])

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
    <Page route={props.route} pageTarget={props.pageTarget} renderTarget={props.renderTarget}>
    <Suspense fallback={<Loader />}>
      <Switch>
        {renderRoutes(props.route.routes, { pageTarget: props.pageTarget, renderTarget: props.renderTarget })}
      </Switch>
    </Suspense>
    </Page>

    </MuiPickersUtilsProvider>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(MarketRoute)
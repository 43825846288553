import { apiGetLatestTermsVersion } from 'services/api/terms.getLatestTermsVersion'
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { i18n } from 'services/i18n/i18n.context'
import { login, setHasPassword } from 'services/auth/auth.slice'
import { pathname } from 'app/routing/routes'
import { redirect } from 'services/routing/route.lib'
import { RootState } from 'app/store'
import { setMarketLang } from 'services/i18n/i18n.slice'
import { setSnackbar } from 'components/page/snackbar/snackbar.slice'

const initialState = {
    email: '',
    password: '',
    loginIsDisabled: true,
    showPassword: false,
    emailIsInError: false,
    passwordIsInError: false,
    formIsInError: false,
    showEmailHintText: false,
    showPasswordHintText: false,
    formHintText: '',
    forgottenPasswordText: '',
    loginInProgress: false,
    oneTimeGreeting: '',
    forceRender: 0,
    targetPath: ''
}

export type LoginState = typeof initialState

export const getLatestTermsVersion = async () => {
    const result = await apiGetLatestTermsVersion()
    if (!result.isSuccess) return null
    return result.data
}

export type IDoLogin = {
    storage: any,
    history: any
}

export const performLogin = createAsyncThunk(
    'login/performLogin',
    async (args: IDoLogin, thunkApi) => {
        try {

            const { dispatch, getState } = thunkApi
            const state = getState() as RootState

            const lastStorageState = args.storage.state
            const { userName: storageName } = lastStorageState

            const name = storageName === undefined ? state.login.email : storageName

            const response = await dispatch(login({ username: name, password: state.login.password }))
            const respAny = response.payload as any
            if (respAny.isSuccess) {
                args.storage.state = { ...lastStorageState, userName: name, lastLoginMethod: 'Manual' }
                setSnackbar({
                    content: i18n('Login.Result.Success'),
                    severity: 'info',
                    autoHideDuration: 4000
                })
                dispatch(setMarketLang({ marketId: respAny.data.market, language: respAny.data.lang }))
                dispatch(setHasPassword(respAny.data.hasPassword))

                // Redirect to Home
                redirect(args.history, state.login.targetPath || pathname.home)
                // Reset targetPath
                dispatch(loginActions.setTargetPath(''))
                return true
            }
            return thunkApi.rejectWithValue(i18n(response.payload as string))
        } catch (err) {
            return thunkApi.rejectWithValue(err.message)
        }
    }
)

export type IStorageValue = {
    storageName: string | undefined,
    value: string
}

export const loginSlice = createSlice({
    name: 'login',
    initialState,
    reducers: {
        reset: (state) => {
            return { ...initialState }
        },
        handleChangeEmail: (state, action: PayloadAction<IStorageValue>) => {
            const loginIsDisabled = state.password.length < 6 || (action.payload.storageName === undefined && action.payload.value.length === 0)
            state.email = action.payload.value
            state.loginIsDisabled = loginIsDisabled
        },
        handleChangePassword: (state, action: PayloadAction<IStorageValue>) => {
            const loginIsDisabled = (state.email.length === 0 && action.payload.storageName === undefined) || action.payload.value.length < 6
            state.password = action.payload.value
            state.loginIsDisabled = loginIsDisabled
        },
        handleClickShowPassword: (state) => {
            state.showPassword = !state.showPassword
        },
        emailOnFocus: (state) => {
            state.showEmailHintText = true
        },
        emailOnBlur: (state) => {
            state.showEmailHintText = false
        },
        passwordOnFocus: (state) => {
            state.showPasswordHintText = true
        },
        passwordOnBlur: (state) => {
            state.showPasswordHintText = false
        },
        setOneTimeGreeting: (state, action: PayloadAction<string>) => {
            state.oneTimeGreeting = action.payload
        },
        forceRender: (state) => {
            state.forceRender += 1
        },
        setTargetPath: (state, action: PayloadAction<string>) => {
          state.targetPath = action.payload
        }
    },
    extraReducers: builder => {
        builder
            .addCase(performLogin.pending, (state, action) => {
                state.loginIsDisabled = true
                state.loginInProgress = true
                state.formIsInError = false
            })
            .addCase(performLogin.fulfilled, (state, action) => {
                state.loginIsDisabled = false
                state.loginInProgress = false
            })
            .addCase(performLogin.rejected, (state, action) => {
                state.loginIsDisabled = false
                state.loginInProgress = false
                state.formIsInError = true
                state.formHintText = action.payload as string
            })
    }
})

export const loginActions = loginSlice.actions;

export default loginSlice.reducer;

import React from 'react'
import tinyI18n from 'tiny-i18n'

export const i18n = tinyI18n.i18n
export const getWord = tinyI18n.getWord
export const getCurrentLanguage = tinyI18n.getCurrentLanguage

export const tNoError = (key: string, ...argv: any[]) => {
  try {
    const current = getCurrentLanguage()
    const value = getWord(key, current)
    if (typeof value !== 'string') return key
    return i18n(key, ...argv)
  } catch (e) {
    console.error(e)
    return key
  }
}

export const i18nWrapper = (langLoaded: boolean): Function => {

  if (langLoaded) {
    return (key: string, ...argv: any[]) => {
      return i18n(key, ...argv)
    }
  }
  return () => '...'
}

export const i18nContext = React.createContext(
  { t: i18nWrapper(false) }
)
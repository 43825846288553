import GCTextIcon from 'components/icons/GCTextIcon'
import React from 'react'
import { Box, useTheme } from '@material-ui/core'
import { useLoaderStyles } from './Loader.styles'

function Loader(props: any) {

  const theme = useTheme()
  const classes = useLoaderStyles(theme)

  return (
    <Box className={classes.root}>
      <GCTextIcon className={classes.icon} />
    </Box>
  )
}

export default Loader
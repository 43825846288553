import { axiosAuthInstance } from "services/http/http.context"
import { i18n } from "services/i18n/i18n.context"
import { resetSessionTimeout } from "services/session/session"
import { resultWrapper } from "./resultWrapper"

type progressInfo = {
  inProgress: boolean,
  timestamp: number,
  promise: any,
  data: any,
  message: string,
  isSuccess: boolean,
  invalid: boolean,
}

export class CachedRequest {

  private progress = new Map<string, progressInfo>()
  public cycleSessionTimeout = true

  constructor(
    private cacheTime: number
  ) { }


  public async post(endpoint: string, args: any, key = 'none') {
    const exists = this.progress.get(key)

    // If cache entry exists
    if (exists && exists.timestamp > Date.now() - this.cacheTime && !exists.invalid) {
      // console.info(`Cache hit for ${ endpoint } with key ${ key }`)
      // Make sure it's finished
      await exists.promise
      // Then return the details
      return { isSuccess: exists.isSuccess, message: exists.message, data: exists.data } as resultWrapper
    }

    // Otherwise, create the cache entry
    const info = {
      inProgress: true,
      timestamp: Date.now(),
      promise: undefined,
      isSuccess: false,
      message: 'Processing',
      data: undefined,
      invalid: false
    } as progressInfo

    // Save it all to cache
    this.progress.set(key, info)

    // Start the async call running
    info.promise = new Promise(async (resolve) => {
      try {
        const response = await axiosAuthInstance.post(endpoint, args)

        // If not ok
        if (response.status !== 200) {
          info.isSuccess = false
          info.message = i18n(response.statusText as string)
          info.data = response.data
          info.inProgress = false
          resolve(false)
        } else {
          info.isSuccess = true
          info.message = 'OK'
          info.data = response.data
          info.inProgress = false
          resolve(true)
        }

      } catch (err) {
        info.isSuccess = false
        info.message = err.response?.data ?? err.message
        info.data = undefined
        info.inProgress = false
        resolve(false)
      }
    })

    // If we're doing something initiated by the user -- reset the session timeout 
    if (this.cycleSessionTimeout) resetSessionTimeout()

    // Wait for the async call to finish
    await info.promise

    // Then return the details
    return { isSuccess: info.isSuccess, message: info.message, data: info.data } as resultWrapper

  } 

  public async get(endpoint: string, key = 'none') {
      const exists = this.progress.get(key)
  
      // If cache entry exists
      if (exists && exists.timestamp > Date.now() - this.cacheTime && !exists.invalid) {
        // console.info(`Cache hit for ${ endpoint } with key ${ key }`)
        // Make sure it's finished
        await exists.promise
        // Then return the details
        return { isSuccess: exists.isSuccess, message: exists.message, data: exists.data } as resultWrapper
      } 
  
      // Otherwise, create the cache entry
      const info = {
        inProgress: true,
        timestamp: Date.now(),
        promise: undefined,
        isSuccess: false,
        message: 'Processing',
        data: undefined,
        invalid: false
      } as progressInfo
  
      // Save it all to cache
      this.progress.set(key, info)
  
      // Start the async call running
      info.promise = new Promise(async (resolve) => {
        try {
          const response = await axiosAuthInstance.get(endpoint)
  
          // If not ok
          if (response.status !== 200) {
            info.isSuccess = false
            info.message = i18n(response.statusText as string)
            info.data = response.data
            info.inProgress = false
            resolve(false)
          } else {
            info.isSuccess = true
            info.message = 'OK'
            info.data = response.data
            info.inProgress = false
            resolve(true)
          }
  
        } catch (err) {
          info.isSuccess = false
          info.message = err.response?.data ?? err.message
          info.data = undefined
          info.inProgress = false
          resolve(false)
        }
      })
  
      // If we're doing something initiated by the user -- reset the session timeout 
      if (this.cycleSessionTimeout) resetSessionTimeout()

      // Wait for the async call to finish
      await info.promise
  
      // Then return the details
      return { isSuccess: info.isSuccess, message: info.message, data: info.data } as resultWrapper
  
    }

  public invalidateCache() {
   this.progress.forEach((value) => value.invalid = true)
  }
}
import Loader from 'components/loader/Loader'
import React, { Suspense } from 'react'
import { BrowserRouter, Switch } from 'react-router-dom'
import { connect } from 'react-redux'
import { CssBaseline } from '@material-ui/core'
import { defaultStorage, StorageContext } from 'services/storage/storage'
import { i18nContext } from 'services/i18n/i18n.context'
import { i18nWrapper } from 'services/i18n/i18n.context'
import { renderRoutes } from 'react-router-config'
import { RootState } from 'app/store'
import { routes } from 'app/routing/routes'
import { selectLangLoaded } from 'services/i18n/i18n.slice'
import { selectTheme } from 'services/theme/theme.slice'
import { ThemeProvider } from '@material-ui/core/styles'

const mapState = (state: RootState) => {
  return {
    langLoaded: selectLangLoaded(state),
    theme: selectTheme(state)
  }
}

function App(props: any) {

  const { theme, langLoaded } = props

  return (
    <Suspense fallback={<Loader />}>
      <StorageContext.Provider value={defaultStorage}>
        <i18nContext.Provider value={{ t: i18nWrapper(langLoaded) }}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <BrowserRouter forceRefresh={false}>
              <Switch>
                {renderRoutes(routes)}
              </Switch>
            </BrowserRouter>
          </ThemeProvider>
        </i18nContext.Provider>
      </StorageContext.Provider>
    </Suspense>
  )
}

export default connect(mapState)(App)


//--------------- New styles

const buttonColor = "#1A2028"
// const headlineColor = "#0D2950"
const brandColor = "#DC337A"
// const brandColorGradient = "linear-gradient(180deg, #FE6C03 0%, #FE03C7 100%);"
//const modalBackground = "linear-gradient(140.94deg, #F2F7FF 17.28%, #FFFFFF 83.98%);"
const textColor = "#242440"

//---------------


// const primary = "#b3294e"
// const secondary = "#4829B2"

const error = "#ee2211"
const warning = "rgba(253, 200, 69, .5)"
const info = "#1976d2"
const success = "#1912d2"

const black = "#343a40";
const darkBlack = "#222222"
const lightWhite = "#cecece"

const paperBackground = "#f1f1f1"
const background = "#ffffff"

// border
const borderWidth = 2
const borderColor = "rgba(0, 0, 0, 0.13)"

// breakpoints
const xl = 1920
const lg = 1280
const md = 960
const sm = 600
const xs = 0

const tonalOffset = 0.2

// spacing
const spacing = 8;

export const globalThemeStyles = {
    palette: {
        primary: { 
          main: brandColor,
          dark: '#f73c5a',
          contrastText: '#fff'
        },
        secondary: {
          main: buttonColor,
          dark: '#444',
          contrastText: '#fff'
        },
        common: {
          black,
          darkBlack,
          lightWhite,
          brand: 'red',
        },
        error: {main: error},
        warning: { main: warning },
        info: { main: info},
        success: { main: success},
        tonalOffset: tonalOffset,
        background: {
          paper: paperBackground,
          default: background
        },
    },
    typography: {
      fontFamily: `"Rubik", "Roboto", "Helvetica", "Arial", sans-serif`,
      h1: {
        fontFamily:  `"Rubik", "sans-serif"`,
        fontWeight: 2000,
        fontSize: "3rem",
      },
    },
    text: {
      primary: textColor,
    },
    breakpoints: {
       values:{ xs, sm, md, lg, xl }
    },
    border: {
        borderColor: borderColor,
        borderWidth: borderWidth
     },
    spacing
}

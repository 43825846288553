import BackIcon from 'components/icons/BackIcon'
import CloseIcon from 'components/icons/CloseIcon'
import React from 'react'
import { Box, useTheme } from '@material-ui/core'
import { connect } from 'react-redux'
import { modalController } from 'components/page/modal/Modal.controller'
import { modalStyles } from 'components/page/modal/Modal.styles'
import { nextModal, clearAllModals } from 'reducers/modal.slice'
import { renderRoutes } from 'react-router-config'
import { RootState } from 'app/store'
import { Switch, useHistory } from 'react-router-dom'
import clsx from 'clsx'

const mapStateToProps = (state: RootState) => {
  const { modal } = state
  return { ...modal }
}

const mapDispatchToProps = {
  nextModal, clearAllModals
}

function Modal(props: any) {
  const history = useHistory()
  const controller = new modalController(
    history,
    props.pageTarget,
    props.modals,
    props.nextModal,
    props.clearAllModals
  )

  const { modalParams, location } = props.modals[0]
  const noBackdrop = modalParams?.noBackdrop ?? false

  const theme = useTheme()
  const classes = modalStyles(theme)

  return (
    <Box className={classes.root}>
      <Box className={clsx({ [classes.backdrop]: !noBackdrop })}></Box>
      <Box className={classes.modalHolder} style={modalParams.customHolderStyles}>
        <Box className={classes.header}>
          <Box className={classes.modalNavigation}>
            <Box className={classes.back}>
              {modalParams?.backButton && props.modals.length > 1 &&
                <BackIcon className={classes.backIcon} onClick={controller.goBack} />
              }
            </Box>
            <Box className={classes.close}>
              {modalParams?.closeButton &&
                <CloseIcon className={classes.closeIcon} onClick={controller.close} />
              }
            </Box>
          </Box>
        </Box>

        <Box className={classes.content}>
          <Switch location={location}>
            {renderRoutes(props.route.routes, { renderTarget: props.renderTarget, pageTarget: props.pageTarget })}
          </Switch>
        </Box>
      </Box>
    </Box>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Modal)

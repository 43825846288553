import { pushEvent } from "services/analytics/ga"

const usingStandalone = 'openedApp'
const switchedToStandalone = 'browserToApp'
const switchedToBrowser = 'appToBrowser'

export enum pwaDisplayMode {
  twa,
  browser,
  standalone
}

export const getPWADisplayMode = () => {

  const isStandalone = window.matchMedia('(display-mode: standalone)').matches
  
  if (document.referrer.startsWith('android-app://')) {
    return pwaDisplayMode.twa
  } else if (navigator.standalone || isStandalone) {
    return pwaDisplayMode.standalone
  }
  
  return pwaDisplayMode.browser
}

export const matchMediaEvent = (evt: any) => {
  let displayMode = pwaDisplayMode.browser
  if (evt.matches) {
    displayMode = pwaDisplayMode.standalone
  }

  switch (displayMode) {
    case pwaDisplayMode.browser:
      pushEvent({
        event: switchedToBrowser
      })
      break
    case pwaDisplayMode.standalone:
      pushEvent({
        event: switchedToStandalone
      })
      break
  }
}



export const setupPWAAnalytics = () => {

 // Check to see if we're currently running in standalone mode.
  const displayMode = getPWADisplayMode()
  switch (displayMode) {
    case pwaDisplayMode.twa:
    case pwaDisplayMode.standalone:
      pushEvent({
        event: usingStandalone
      })
  }

  // Install event Listener to monitor display mode changes
  window.matchMedia('(display-mode: standalone)').removeEventListener('change', matchMediaEvent)
  window.matchMedia('(display-mode: standalone)').addEventListener('change', matchMediaEvent)

}
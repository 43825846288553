import { RootState } from 'app/store'
import React from 'react'
import {Helmet} from "react-helmet"
import { connect } from 'react-redux'
import { i18nContext, getWord } from 'services/i18n/i18n.context'

const mapStateToProps = (state: RootState) => {
    const {i18n: { lang, langLoaded } } = state
    return { lang, langLoaded }
  }
  

function MetaTags(props: any) {

    // Dynamic language handling
  const i18nCtx = React.useContext(i18nContext)
  const { t } = i18nCtx

  if (!props.langLoaded) return(<></>)

  const pageMetaDesc = `Page.${props.pageTitle}.Meta.Description`
  let metaDesc = getWord(pageMetaDesc)
  if (typeof metaDesc !== 'string') metaDesc = t(`Page.Default.Meta.Description`)

  const pageMetaKeys = `Page.${props.pageTitle}.Meta.Keys`
  let metaKeys = getWord(pageMetaKeys)
  if (typeof metaKeys !== 'string') metaKeys = t(`Page.Default.Meta.Keys`)

    return (
        <Helmet>
            <meta name="description" content={metaDesc} />
            <meta name="keywords" content={metaKeys} />
            <link rel="canonical" href="https://gamblersconcierge.com/" />
        </Helmet>
    )
}

export default connect(mapStateToProps)(MetaTags)
import { makeStyles } from '@material-ui/core'

export const menuBottomHeight = 64

export const menuWideStyles = makeStyles((theme) => ({
  // Logged Out view
  rootLoggedOut: {
    display: 'flex',
    flexDirection: 'row',
    spacing: 0,
    padding: 0,
    margin: 0,
    height: '100%',
    backgroundColor: '#67FFFF',
    alignItems: 'center',
    justifyContent: 'center'
  },
  signupCta: {
  },
  ctaText: {
    color: '#393939',
  },


  root :{
    display: 'flex',
    flexDirection: 'row',
    spacing: 0,
    padding: 0,
    margin: 0,
    height: '100%',
    width: '100%',
    backgroundColor: '#202020',
    verticalAlign: 'top'
  },
  menuItem: {
    display: 'flex',
    flexDirection: 'column', 
    width: '20%',
    alignItems: 'center',
    justifyContent: 'flex-end',
    cursor: 'pointer'
  },
  menuDivider: {
    width: 1,
    maxWidth: 1,
    opacity: 0.3
  },
  menuLink: {
    display: 'flex',
    flexDirection: 'column', 
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '100%',
    height: '100%'
  },
  verticalsIcon: { 
    marginBottom: 3
  },
  newsIcon: { 
    marginBottom: 6
  },
  myBrandsIcon: {
    width: 24,
    height: 24,
    marginBottom: 2
  },
  homeIcon: {
    marginBottom: 10
  },
  trackIcon: {
    marginBottom: 6
  },
  userIcon: {
    marginBottom: 4
  },
  jackpotIcon: {
    marginBottom: 4
  },
  BetFinderIcon: {
    marginBottom: 4,
    width: 24,
    height: 24,
    '& path': {
      fill: '#fff',
    }
  },
  iconText: {
    textDecoration: 'none',
    fontSize: 12,
    height: 25,
    color: '#FFF',
    opacity: 0.3,
    textAlign: 'center'
  },
  selected: {
   color: theme.palette.text.primary,
  },
  verticalMenu: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    paddingBottom: 10,
    backgroundColor: '#222',
  },
  verticalMenuItem: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: 55,
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  verticalMenuIcon: {
    display: 'flex',
    marginLeft: 27,
  },
  verticalDivider: {
    alignSelf: 'center',
  },
  verticalText: {
    display: 'flex',
    textDecoration: 'none',
    fontSize: 20,
    color: '#FFF',
    marginLeft: 11,
    textAlign: 'center'
  },

}))

export const menuWideFabStyles = makeStyles((theme) => ({
  primary: {
    color: '#393939',
  }
}), {name: 'MuiFab'})
import { ActionCreatorWithoutPayload } from '@reduxjs/toolkit'
import { GCRoute } from 'app/routing/routes'
import { paramsForModal } from 'reducers/modal.slice'
import { rebaseMarketLang } from 'services/routing/route.lib'

export class modalController {
  
  public constructor(
    private history: any,
    private pageTarget: GCRoute,
    private modals: paramsForModal[],
    private nextModal: ActionCreatorWithoutPayload<string>,
    private clearAllModals: ActionCreatorWithoutPayload<string>
  ){}

  public goBack = () => {
    this.nextModal()
    let route
    if (this.modals.length === 1) {
      route = rebaseMarketLang(this.pageTarget.match.url, this.pageTarget.match.params)
    } else {
      route = this.modals[1].location
    }
    this.history.push(route)
  }

  public close = () => {
    this.clearAllModals()
    const route = rebaseMarketLang(this.pageTarget.match.url, this.pageTarget.match.params)
    this.history.push(route)
  }
}
import * as serviceWorker from './serviceWorkerRegistration'
import App from 'app/App'
import React from 'react'
import ReactDOM from 'react-dom'
import { getCorrectedUrl } from 'services/lang/lang.lib'
import { isAuthenticated, setLoggedIn } from 'services/auth/auth.slice'
import { onLogin } from 'services/auth/auth.hook'
import { Provider } from 'react-redux'
import { store } from 'app/store'
import 'fontsource-rubik'
import { setupPWAAnalytics } from 'services/pwa/displayMode'

// Restore logged in status
isAuthenticated().then((auth) => {
  if (auth) {
    store.dispatch(setLoggedIn())
    onLogin()
  }
})
  .catch((e) => { })
  .finally(() => {
    // Check & redirect URL if required
    getCorrectedUrl(window.location.pathname, store).then((revisedPath) => {
      if (window.location.pathname !== revisedPath) {
        window.location.pathname = revisedPath
      }
      else {
        ReactDOM.render(
          <React.StrictMode>
            <Provider store={store}>
              <App />
            </Provider>
          </React.StrictMode>,
          document.getElementById('root')
        )
      }

      // If you want your app to work offline and load faster, you can change
      // unregister() to register() below. Note this comes with some pitfalls.
      // Learn more about service workers: https://bit.ly/CRA-PWA
      serviceWorker.register()
      setupPWAAnalytics()
    })
  }) 
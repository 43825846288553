import { store } from "app/store"
import { selectLang, selectMarket } from "services/i18n/i18n.slice"
import { defaultLangForMarket, detectBrowserLanguage } from "services/lang/lang.lib"

export const redirect = (history: any, path: string) => {
    const redirectRoute = getRedirectRoute()
    const target ={ pathname: redirectRoute + path}
    history.push(target)
  }

export const removeMarketLang = (path: string, params: any) => {
  for (const p in params) {
    path = path.replace('/' + params[p] + '/', '/')
  }
  while (path[0] === '/') {
    path = path.slice(1)
  }
  return path
}


// Remove a path's marketlang and replace with the correct marketlang
export const rebaseMarketLang = (path: string, params: any) => {
  const filterParams = Object.keys(params).filter(p => p === 'market' || p === 'lang')
  for (const p of filterParams) {
    path = path.replace('/' + params[p] + '/', '/')
  }
  while (path[0] === '/') {
    path = path.slice(1)
  }
  return getRedirectRoute() + path
}

const getRedirectRoute = () : string => {
  const state = store.getState()

  let stateMarket = selectMarket(state)
  let stateLang = selectLang(state)

  if (stateMarket.length > 2) stateMarket = ''
  if (stateLang.length > 2) stateLang = ''

  const defaultMarket = stateMarket === '' ? detectBrowserLanguage() : stateMarket
  const defaultLang = stateLang === '' ? defaultLangForMarket(defaultMarket) : stateLang

  const redirectRoute = defaultLang === defaultMarket ? `/${defaultMarket}/` : `/${defaultMarket}/${defaultLang}/`
  return redirectRoute
}

export const normalizeReviewUrl = (url: string): string => {
  const state = store.getState()
  let stateMarket = selectMarket(state)
  if (stateMarket.length > 2) stateMarket = ''

  let searchString = ''
  switch(stateMarket) {
    case 'ja':
      searchString = 'japangamblers.com/'
  }

  if (searchString === '') return url

  const idx = url.toLowerCase().indexOf(searchString)
  if (idx < 0) return url
  url = url.slice(idx + searchString.length)

  return url
}